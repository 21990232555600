//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function CK497K(e){
    //Element
    e.preventDefault()
    //Element
    let HS738F, H62N34, W46BC9, M29F4E
    //Element
    HS738F = document.getElementById( "Web_N6J080" ).value
    //Element
    H62N34 = document.getElementById( "Web_QC6M40" ).value
    //Element
    W46BC9 = document.getElementById( "Web_N3Q236" ).value
    //Element
    M29F4E = document.getElementById( "Web_M025PK" ).value
    //Element
    const P43WK2 = async() => {
        //Element
        const DY010N = await Axios.get( "https://api.renga.com.tr/bottle/update", {
            //Element
            params : {
                //Element
                ID       : HS738F,
                //Element
                Title    : H62N34,
                //Element
                Category : W46BC9,
                //Element
                Date     : M29F4E
            }
        })
        //Element
        .catch( X5D334 => {
            //Element
            console.log( X5D334 )
        })
    }
    //Element
    P43WK2()
    //Element
    window.location.href = "/bottle"
}
//Element
function RCV721(e){ 
    //Element
    e.preventDefault()
    //Element
    let B0S517 = document.getElementById( "Web_N6J080" ).value
    //Element
    const SV274J = async() => {
        //Element
        const V147IQ = await Axios.get( "https://api.renga.com.tr/bottle/delete", {
            //Element
            params : {
                //Element
                ID : B0S517
            }
        })
        //Element
        .catch( H3056V => {
            //Element
            console.log( H3056V )
        })
    }
    //Element
    SV274J()
    //Element
    window.location.href = "/bottle"
}
//Element
function Edit(){
    //Element
    const { ID } = useParams()
    //Element
    let J8P1M0, Y229A0, H57A90
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    const [ D79551, IK946T ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const M282NS = async() => {
            //Element
            const N88694 = await Axios.get( "https://api.renga.com.tr/bottle/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            } )
            //Element
            C68006( N88694.data )
        }
        //Element
        M282NS()
        //Element
        const M10268 = async() => {
            //Element
            const NM9557 = await Axios.get( "https://api.renga.com.tr/variety", {} )
            //Element
            IK946T( NM9557.data )
        }
        //Element
        M10268()
    }, [] )
    //Element
    G7TI26.map( (AE7U86) => {
        //Element
        J8P1M0 = AE7U86.Title
        //Element
        document.getElementById( "Web_N3Q236" ).value = AE7U86.Category
        //Element
        H57A90 = AE7U86.Date
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Matara"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Bilgi Güncelleme"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Matara ID"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_N6J080", name: "Web_N6J080", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Matara Adı"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_QC6M40", name: "Web_QC6M40", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: J8P1M0, type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Kategori"
                    ),
                    //Element
                    React.createElement( "select", { id: "Web_N3Q236", name: "Web_N3Q236", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                        //Element
                        D79551.map( (H3419W) => {
                            //Element
                            return React.createElement( "option", { key: H3419W.ID, value: H3419W.ID },
                                //Element
                                H3419W.Title
                            )
                        })
                    )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Tarih"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_M025PK", name: "Web_M025PK", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: H57A90, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_VE5330", name: "Web_VE5330", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: RCV721 },
                                    //Element
                                    "Sil"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_XL38A4", name: "Web_XL38A4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: CK497K },
                                    //Element
                                    "Güncelle"
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;