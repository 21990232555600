//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import { CKEditor } from "@ckeditor/ckeditor5-react";
//Install
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//Install
import Upload from "../../Shortcode/Product/Image";

//Element
class Edit extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        const SO268R = window.location.origin + "/product-edit/"
        //Element
        const R96278 = window.location.href.replace( SO268R, "" )
        //Element
        this.state = {
            //Element
            OD894D : R96278,
            //Element
            K6L563 : "",
            //Element
            LI259T : [],
            //Element
            DAR414 : [],
            //Element
            F984US : [],
            //Element
            N6I51E : []
        }
    }
    //Element
    K37Z59 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let MGV429, O34U7G, R786MQ, LE0628, P1K643, P969X5, C4Y46F, F9O8G5, Y00R73, P2R735, O4CE93, A269C8, T574N5, E4I16W, B89H87, R6A7H6, Q8568D, W0527M, P107VD, B998JM
        //Element
        let INW968, CMR289, G7Y3U9, TT0G95, Y84U17, D04498, C575NQ, K5251P, M317P5
        //Element
        let AS1M96, E85H63, C704DC
        //Element
        MGV429 = this.state.OD894D
        //Element
        O34U7G = document.getElementById( "Web_U45Q45" ).value
        //Element
        R786MQ = document.getElementById( "Web_LK55R0" ).value
        //Element
        B89H87 = document.getElementById( "Web_K4F83W" ).value
        //Element
        LE0628 = document.getElementsByClassName( "ck-editor__editable_inline" )[0].innerHTML
        //Element
        P1K643 = sessionStorage.getItem( "Web_N518EM" )
        //Element
        P969X5 = document.getElementById( "Web_GV376I" ).value
        //Element
        C4Y46F = document.getElementById( "Web_D642MB" ).value
        //Element
        E4I16W = document.getElementById( "Web_W34HX9" ).value
        //Element
        F9O8G5 = document.getElementById( "Web_T134PW" ).value
        //Element
        Y00R73 = document.getElementById( "Web_P65395" ).value
        //Element
        P2R735 = document.getElementById( "Web_Y71949" ).value
        //Element
        O4CE93 = document.getElementById( "Web_GO973H" ).value
        //Element
        A269C8 = document.getElementById( "Web_EW280E" ).value
        //Element
        T574N5 = document.getElementById( "Web_R4399Q" ).value
        //Element
        R6A7H6 = document.getElementById( "Web_F3AG13" ).value
        //Element
        Q8568D = document.getElementById( "Web_M073CM" ).value
        //Element
        W0527M = document.getElementById( "Web_VT4J18" ).value
        //Element
        P107VD = document.getElementById( "Web_CP177E" ).value
        //Element
        B998JM = document.getElementById( "Web_E684KO" ).value
        //Element
        AS1M96 = document.getElementById( "Web_J812S4" ).value
        //Element
        E85H63 = document.getElementById( "Web_U422LT" ).value
        //Element
        C704DC = document.getElementById( "Web_XD567D" ).value
        //Element
        INW968 = sessionStorage.getItem( "Web_L3N3U8" )
        //Element
        CMR289 = sessionStorage.getItem( "Web_N7O7F6" )
        //Element
        G7Y3U9 = sessionStorage.getItem( "Web_Y748IW" )
        //Element
        TT0G95 = sessionStorage.getItem( "Web_K4J317" )
        //Element
        Y84U17 = sessionStorage.getItem( "Web_D3842S" )
        //Element
        D04498 = sessionStorage.getItem( "Web_L1D37N" )
        //Element
        C575NQ = sessionStorage.getItem( "Web_YN2X81" )
        //Element
        K5251P = sessionStorage.getItem( "Web_CXO598" )
        //Element
        M317P5 = sessionStorage.getItem( "Web_U22E35" )
        //Element
        const Q1E36Z = async() => {
            //Element
            const C1NW86 = await Axios.get( "https://api.renga.com.tr/product/update", {
                //Element
                params : {
                    //Element
                    Unique_ID : B89H87,
                    //Element
                    ID        : MGV429,
                    //Element
                    Title     : O34U7G,
                    //Element
                    SubTitle  : R786MQ,
                    //Element
                    Summary   : LE0628,
                    //Element
                    Image     : P1K643,
                    //Element
                    Variety   : P969X5,
                    //Element
                    Bottle    : C4Y46F,
                    //Element
                    Volume    : F9O8G5,
                    //Element
                    Date      : Y00R73,
                    //Element
                    Author    : P2R735,
                    //Element
                    iFrame    : E4I16W,
                    //Element
                    Cubic     : R6A7H6,
                    //Element
                    Weight    : Q8568D,
                    //Element
                    Piece     : W0527M,
                    //Element
                    Packing   : P107VD,
                    //Element
                    Barcode   : B998JM,
                    //Element
                    Tag_One   : AS1M96,
                    //Element
                    Tag_Two   : E85H63,
                    //Element
                    Tag_Three : C704DC
                }
            })
            //Element
            .then( L17608 => {
                //Element
                if( L17608.statusText == "OK" ){
                    //Element
                    const C155EU = async() => {
                        //Element
                        const DX178H = await Axios.get( "https://api.renga.com.tr/product/color/select", {
                            //Element
                            params : {
                                //Element
                                ID : MGV429,
                            }
                        })
                        //Element
                        .then( NQ9Y59 => {
                            //Element
                            if( NQ9Y59.data.length == "0" ){
                                //Element
                                Axios.get( "https://api.renga.com.tr/product/color/add", {
                                    //Element
                                    params : {
                                        //Element
                                        ID     : MGV429,
                                        //Element
                                        First  : O4CE93,
                                        //Element
                                        Second : A269C8,
                                        //Element
                                        Third  : T574N5
                                    }
                                })
                                //Element
                                .then( T9323L => {
                                    //Element
                                    if( T9323L.statusText == "OK" ){
                                        //Element
                                        Axios.get( "https://api.renga.com.tr/product/image/add", {
                                            //Element
                                            params : {
                                                //Element
                                                ID     : MGV429,
                                                //Element
                                                First  : INW968,
                                                //Element
                                                Second : CMR289,
                                                //Element
                                                Third  : G7Y3U9
                                            }
                                        })
                                        //Element
                                        .then( X4817Y => {
                                            //Element
                                            if( X4817Y.statusText == "OK" ){
                                                //Element
                                                sessionStorage.removeItem( "Web_N518EM" )
                                                //Element
                                                sessionStorage.removeItem( "Web_L3N3U8" )
                                                //Element
                                                sessionStorage.removeItem( "Web_N7O7F6" )
                                                //Element
                                                sessionStorage.removeItem( "Web_Y748IW" )
                                                //Element
                                                window.location.href = "/product-order"
                                            }
                                        })
                                    }
                                })
                            //Element
                            } else {
                                //Element
                                Axios.get( "https://api.renga.com.tr/product/color/update", {
                                    //Element
                                    params     : {
                                        //Element
                                        ID     : MGV429,
                                        //Element
                                        First  : O4CE93,
                                        //Element
                                        Second : A269C8,
                                        //Element
                                        Third  : T574N5
                                    }
                                })
                                //Element
                                .then( T9323L => {
                                    //Element
                                    if( T9323L.statusText == "OK" ){
                                        //Element
                                        Axios.get( "https://api.renga.com.tr/product/image/update", {
                                            //Element
                                            params : {
                                                //Element
                                                ID      : MGV429,
                                                //Element
                                                First   : INW968,
                                                //Element
                                                Second  : CMR289,
                                                //Element
                                                Third   : G7Y3U9,
                                                //Element
                                                Fourth  : TT0G95,
                                                //Element
                                                Fifth   : Y84U17,
                                                //Element
                                                Sixth   : D04498,
                                                //Element
                                                Seventh : C575NQ,
                                                //Element
                                                Eighth  : K5251P,
                                                //Element
                                                Eighth  : K5251P,
                                                //Element
                                                Ninth   : M317P5
                                            }
                                        })
                                        //Element
                                        .then( X4817Y => {
                                            //Element
                                            if( X4817Y.statusText == "OK" ){
                                                //Element
                                                sessionStorage.removeItem( "Web_N518EM" )
                                                //Element
                                                sessionStorage.removeItem( "Web_L3N3U8" )
                                                //Element
                                                sessionStorage.removeItem( "Web_N7O7F6" )
                                                //Element
                                                sessionStorage.removeItem( "Web_Y748IW" )
                                                //Element
                                                sessionStorage.removeItem( "Web_K4J317" )
                                                //Element
                                                sessionStorage.removeItem( "Web_D3842S" )
                                                //Element
                                                sessionStorage.removeItem( "Web_L1D37N" )
                                                //Element
                                                sessionStorage.removeItem( "Web_YN2X81" )
                                                //Element
                                                sessionStorage.removeItem( "Web_CXO598" )
                                                //Element
                                                sessionStorage.removeItem( "Web_U22E35" )
                                                //Element
                                                window.location.href = "/product-order"
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                    //Element
                    C155EU()
                }
            })
        }
        //Element
        Q1E36Z()
    }
    //Element
    G7Z12B = (e) => {
        //Element
        e.preventDefault()
        //Element
        const V2G997 = this.state.OD894D
        //Element
        const K0E12S = async() => {
            //Element
            const K3E531 = await Axios.get( "https://api.renga.com.tr/product/delete", {
                //Element
                params : {
                    //Element
                    ID : V2G997
                }
            })
            //Element
            .then( U19X07 => {
                //Element
                if( U19X07.statusText == "OK" ){
                    //Element
                    Axios.get( "https://api.renga.com.tr/product/color/delete", {
                        //Element
                        params : {
                            //Element
                            ID : V2G997
                        }
                    })
                    //Element
                    .then( W420GD => {
                        //Element
                        if( W420GD.statusText == "OK" ){
                            //Element
                            Axios.get( "https://api.renga.com.tr/product/image/delete", {
                                //Element
                                params : {
                                    //Element
                                    ID : V2G997
                                }
                            })
                            //Element
                            .then( J9302K => {
                                //Element
                                if( J9302K.statusText == "OK" ){
                                    //Element
                                    window.location.href = "/product-order"
                                }
                            })
                        }
                    })
                }
            })
        }
        //Element
        K0E12S()
    }
    //Element
    R1995S = () => {
        //Element
        const M611CB = async() => {
            //Element
            const OSR099 = await Axios.get( "https://api.renga.com.tr/product/select", {
                //Element
                params : {
                    //Element
                    ID : this.state.OD894D
                }
            })
            //Element
            .then( SSU008 => {
                //Element
                SSU008.data.map( (T9X117) => {
                    //Element
                    document.getElementById( "Web_U45Q45" ).value                                = T9X117.Title
                    //Element
                    document.getElementById( "Web_LK55R0" ).value                                = T9X117.SubTitle
                    //Element
                    document.getElementById( "Web_Y71949" ).value                                = T9X117.Author
                    //Element
                    document.getElementById( "Web_W34HX9" ).value                                = T9X117.iFrame
                    //Element
                    document.getElementById( "Web_K4F83W" ).value                                = T9X117.Unique_ID
                    //Element
                    document.getElementById( "Web_F3AG13" ).value                                = T9X117.Cubic
                    //Element
                    document.getElementById( "Web_M073CM" ).value                                = T9X117.Weight
                    //Element
                    document.getElementById( "Web_VT4J18" ).value                                = T9X117.Piece
                    //Element
                    document.getElementById( "Web_CP177E" ).value                                = T9X117.Packing
                    //Element
                    document.getElementById( "Web_E684KO" ).value                                = T9X117.Barcode
                    //Element
                    document.getElementById( "Web_J812S4" ).value                                = T9X117.Tag_One
                    //Element
                    document.getElementById( "Web_U422LT" ).value                                = T9X117.Tag_Two
                    //Element
                    document.getElementById( "Web_XD567D" ).value                                = T9X117.Tag_Three	
                    //Element
                    document.getElementsByClassName( "ck-editor__editable_inline" )[0].innerHTML = T9X117.Summary
                    //Element
                    sessionStorage.setItem( "Web_N518EM", T9X117.Image )
                    //Element
                    this.setState({
                        //Element
                        K6L563 : T9X117.Summary
                    })
                })
            })
        }
        //Element
        M611CB()
    }
    //Element
    V405J6 = () => {
        //Element
        const X48BR7 = async() => {
            //Element
            const I2O487 = await Axios.get( "https://api.renga.com.tr/variety", {} )
            //Element
            .then( A3OI11 => {
                //Element
                this.setState({
                    //Element
                    LI259T : A3OI11.data
                })
                //Element
                if( A3OI11.statusText == "OK" ){
                    //Element
                    Axios.get( "https://api.renga.com.tr/product/id/variety", {
                        //Element
                        params : {
                            //Element
                            ID : this.state.OD894D
                        }
                    })
                    //Element
                    .then( Z12Z18 => {
                        //Element 
                        document.getElementById( "Web_GV376I" ).value = Z12Z18.data[0].Variety
                        //Element
                        Axios.get( "https://api.renga.com.tr/bottle/category", {
                            //Element
                            params : {
                                //Element
                                ID : Z12Z18.data[0].Variety
                            }
                        })
                        //Element
                        .then( D07762 => {
                            //Element
                            this.setState({
                                //Element
                                N6I51E : D07762.data
                            })
                            //Element
                            Axios.get( "https://api.renga.com.tr/volume/category", {
                                //Element
                                params : {
                                    //Element
                                    ID : Z12Z18.data[0].Variety
                                }
                            })
                            //Element
                            .then( S1J924 => {
                                //Element
                                this.setState({
                                    //Element
                                    F984US : S1J924.data
                                })
                            })
                        })
                    })
                }
            })
        }
        //Element
        X48BR7() 
    }
    //Element
    R2A9Y9 = () => {
        //Element
        const X48BR7 = async() => {
            //Element
            const I2O487 = await Axios.get( "https://api.renga.com.tr/color", {} )
            //Element
            .then( P7475C => {
                //Element
                this.setState({
                    //Element
                    DAR414 : P7475C.data
                })
                //Element
                if( P7475C.statusText == "OK" ){
                    //Element
                    Axios.get( "https://api.renga.com.tr/product/color/select", {
                        //Element
                        params : {
                            //Element
                            ID : this.state.OD894D
                        }
                    })
                    //Element
                    .then( K8A25Y => {
                        //Element
                        K8A25Y.data.map( (CM770R) => {
                            //Element
                            document.getElementById( "Web_GO973H" ).value = CM770R.First
                            //Element
                            document.getElementById( "Web_EW280E" ).value = CM770R.Second
                            //Element
                            document.getElementById( "Web_R4399Q" ).value = CM770R.Third
                        })
                    })
                }
            })
        }
        //Element
        X48BR7()
    }
    //Element
    N644IV = () => {
        //Element
        const X48BR7 = async() => {
            //Element
            const I2O487 = await Axios.get( "https://api.renga.com.tr/bottle", {} )
            //Element
            .then( Z0246C => {
                //Element
                this.setState({
                    //Element
                    N6I51E : Z0246C.data
                })
                //Element
                if( Z0246C.statusText == "OK" ){
                    //Element
                    Axios.get( "https://api.renga.com.tr/product/id/bottle", {
                        //Element
                        params : {
                            //Element
                            ID : this.state.OD894D
                        }
                    })
                    //Element
                    .then( G16235 => {
                        //Element
                        document.getElementById( "Web_D642MB" ).value = G16235.data[0].Bottle
                    })
                }
            })
        }
        //Element
        X48BR7()
    }
    //Element
    X084YW = () => {
        //Element
        const X48BR7 = async() => {
            //Element
            const I2O487 = await Axios.get( "https://api.renga.com.tr/volume", {} )
            //Element
            .then( N9226E => {
                //Element
                this.setState({
                    //Element
                    F984US : N9226E.data
                })
                //Element
                if( N9226E.statusText == "OK" ){
                    //Element
                    Axios.get( "https://api.renga.com.tr/product/id/volume", {
                        //Element
                        params : {
                            //Element
                            ID : this.state.OD894D
                        }
                    })
                    //Element
                    .then( G16235 => {
                        //Element
                        document.getElementById( "Web_T134PW" ).value = G16235.data[0].Volume
                    })
                }
            })
        }
        //Element
        X48BR7()
    }
    //Element
    J535CY = () => {
        //Element
        const I801CU = document.getElementsByClassName( "p43687" )[0]
        //Element
        const XDX942 = document.getElementsByClassName( "klf458" )[0]
        //Element
        const F9F8Y7 = document.getElementById( "Web_O0190T" )
        //Element
        let H422C2 = "https://api.renga.com.tr/images/"
        //Element
        let P1U7W1 = ""
        //Element
        let M165M1 = ""
        //Element
        Axios.get( "https://api.renga.com.tr/product/select", {
            //Element
            params : {
                //Element
                ID : this.state.OD894D
            }
        })
        //Element
        .then( A086S1 => {
            //Element
            A086S1.data.map( (Y97K82) => {
                //Element
                I801CU.classList.add( "d-none" )
                //Element
                XDX942.classList.remove( "d-none" )
                //Element
                P1U7W1     = Y97K82.Image
                //Element
                M165M1     = H422C2 + P1U7W1
                //Element
                F9F8Y7.src = M165M1
                //Element
                sessionStorage.getItem( "Web_N518EM", Y97K82.Image )
            })
        })
    }
    //Element
    E467XE = () => {
        //Element
        let L6Z561 = "https://api.renga.com.tr/images/"
        //Element
        let Y81986 = ""
        //Element
        let O892H9 = ""
        //Element
        const PM942R = document.getElementById( "Web_R494VD" )
        //Element
        const XO6D66 = document.getElementById( "Web_Y123I3" )
        //Element
        const W1SX87 = document.getElementById( "Web_W5F6C8" )
        //Element
        const H75B2K = document.getElementById( "Web_Z869M3" )
        //Element
        const P9Q8W4 = document.getElementById( "Web_J558OJ" )
        //Element
        const F53Q8K = document.getElementById( "Web_Q071RS" )
        //Element
        const G20U26 = document.getElementById( "Web_V343CB" )
        //Element
        const W85L57 = document.getElementById( "Web_B285F7" )
        //Element
        const U623RV = document.getElementById( "Web_T066I5" )
        //Element
        Axios.get( "https://api.renga.com.tr/product/image/select", {
            //Element
            params : {
                //Element
                ID : this.state.OD894D
            }
        })
        //Element
        .then( JU45P4 => {
            //Element
            JU45P4.data.map( (G9G723) => {
                //Element
                if( G9G723.First != null ){
                    //Element
                    document.getElementById( "Web_V32KA9" ).classList.add( "d-none" )
                    //Element
                    document.getElementById( "Web_V5K852" ).classList.remove( "d-none" )
                    //Element
                    Y81986     = G9G723.First
                    //Element
                    O892H9     = L6Z561 + Y81986
                    //Element
                    PM942R.src = O892H9
                    //Element
                    if( sessionStorage.getItem( "Web_L3N3U8" ) ){
                        //Element
                        sessionStorage.removeItem( "Web_L3N3U8" )
                        //Element
                        sessionStorage.setItem( "Web_L3N3U8", G9G723.First )
                    //Element
                    } else {
                        //Element
                        sessionStorage.setItem( "Web_L3N3U8", G9G723.First )
                    }
                }
                //Element
                if( G9G723.Second != null ){
                    //Element
                    document.getElementById( "Web_QR150K" ).classList.add( "d-none" )
                    //Element
                    document.getElementById( "Web_XG45T3" ).classList.remove( "d-none" )
                    //Element
                    Y81986      = G9G723.Second
                    //Element
                    O892H9      = L6Z561 + Y81986
                    //Element
                    XO6D66.src  = L6Z561 + Y81986
                    //Element
                    if( sessionStorage.getItem( "Web_N7O7F6" ) ){
                        //Element
                        sessionStorage.removeItem( "Web_N7O7F6" )
                        //Element
                        sessionStorage.setItem( "Web_N7O7F6", G9G723.Second )
                    //Element
                    } else {
                        //Element
                        sessionStorage.setItem( "Web_N7O7F6", G9G723.Second )
                    }
                }
                //Element
                if( G9G723.Third != null ){
                    //Element
                    document.getElementById( "Web_Q0V66Y" ).classList.add( "d-none" )
                    //Element
                    document.getElementById( "Web_Y1V817" ).classList.remove( "d-none" )
                    //Element
                    Y81986      = G9G723.Third
                    //Element
                    O892H9      = L6Z561 + Y81986
                    //Element
                    W1SX87.src  = L6Z561 + Y81986
                    //Element
                    if( sessionStorage.getItem( "Web_Y748IW" ) ){
                        //Element
                        sessionStorage.removeItem( "Web_Y748IW" )
                        //Element
                        sessionStorage.setItem( "Web_Y748IW", G9G723.Third )
                    //Element
                    } else {
                        //Element
                        sessionStorage.setItem( "Web_Y748IW", G9G723.Third )
                    }
                }
                //Element
                if( G9G723.Fourth != null ){
                    //Element
                    document.getElementById( "Web_I082EZ" ).classList.add( "d-none" )
                    //Element
                    document.getElementById( "Web_C14B2O" ).classList.remove( "d-none" )
                    //Element
                    Y81986      = G9G723.Fourth
                    //Element
                    O892H9      = L6Z561 + Y81986
                    //Element
                    H75B2K.src  = L6Z561 + Y81986
                    //Element
                    if( sessionStorage.getItem( "Web_K4J317" ) ){
                        //Element
                        sessionStorage.removeItem( "Web_K4J317" )
                        //Element
                        sessionStorage.setItem( "Web_K4J317", G9G723.Fourth )
                    //Element
                    } else {
                        //Element
                        sessionStorage.setItem( "Web_K4J317", G9G723.Fourth )
                    }
                }
                //Element
                if( G9G723.Fifth != null ){
                    //Element
                    document.getElementById( "Web_I473TL" ).classList.add( "d-none" )
                    //Element
                    document.getElementById( "Web_W7IX78" ).classList.remove( "d-none" )
                    //Element
                    Y81986      = G9G723.Fifth
                    //Element
                    O892H9      = L6Z561 + Y81986
                    //Element
                    P9Q8W4.src  = L6Z561 + Y81986
                    //Element
                    if( sessionStorage.getItem( "Web_D3842S" ) ){
                        //Element
                        sessionStorage.removeItem( "Web_D3842S" )
                        //Element
                        sessionStorage.setItem( "Web_D3842S", G9G723.Fifth )
                    //Element
                    } else {
                        //Element
                        sessionStorage.setItem( "Web_D3842S", G9G723.Fifth )
                    }
                }
                //Element
                if( G9G723.Sixth != null ){
                    //Element
                    document.getElementById( "Web_Z0C098" ).classList.add( "d-none" )
                    //Element
                    document.getElementById( "Web_C08O21" ).classList.remove( "d-none" )
                    //Element
                    Y81986      = G9G723.Sixth
                    //Element
                    O892H9      = L6Z561 + Y81986
                    //Element
                    F53Q8K.src  = L6Z561 + Y81986
                    //Element
                    if( sessionStorage.getItem( "Web_L1D37N" ) ){
                        //Element
                        sessionStorage.removeItem( "Web_L1D37N" )
                        //Element
                        sessionStorage.setItem( "Web_L1D37N", G9G723.Sixth )
                    //Element
                    } else {
                        //Element
                        sessionStorage.setItem( "Web_L1D37N", G9G723.Sixth )
                    }
                }
                //Element
                if( G9G723.Seventh != null ){
                    //Element
                    document.getElementById( "Web_R453OQ" ).classList.add( "d-none" )
                    //Element
                    document.getElementById( "Web_OJ881E" ).classList.remove( "d-none" )
                    //Element
                    Y81986      = G9G723.Seventh
                    //Element
                    O892H9      = L6Z561 + Y81986
                    //Element
                    G20U26.src  = L6Z561 + Y81986
                    //Element
                    if( sessionStorage.getItem( "Web_YN2X81" ) ){
                        //Element
                        sessionStorage.removeItem( "Web_YN2X81" )
                        //Element
                        sessionStorage.setItem( "Web_YN2X81", G9G723.Seventh )
                    //Element
                    } else {
                        //Element
                        sessionStorage.setItem( "Web_YN2X81", G9G723.Seventh )
                    }
                }
                //Element
                if( G9G723.Eighth != null ){
                    //Element
                    document.getElementById( "Web_N0434L" ).classList.add( "d-none" )
                    //Element
                    document.getElementById( "Web_J72SK4" ).classList.remove( "d-none" )
                    //Element
                    Y81986      = G9G723.Eighth
                    //Element
                    O892H9      = L6Z561 + Y81986
                    //Element
                    W85L57.src  = L6Z561 + Y81986
                    //Element
                    if( sessionStorage.getItem( "Web_CXO598" ) ){
                        //Element
                        sessionStorage.removeItem( "Web_CXO598" )
                        //Element
                        sessionStorage.setItem( "Web_CXO598", G9G723.Eighth )
                    //Element
                    } else {
                        //Element
                        sessionStorage.setItem( "Web_CXO598", G9G723.Eighth )
                    }
                }
                //Element
                if( G9G723.Ninth != null ){
                    //Element
                    document.getElementById( "Web_T7F39L" ).classList.add( "d-none" )
                    //Element
                    document.getElementById( "Web_O80M2K" ).classList.remove( "d-none" )
                    //Element
                    Y81986      = G9G723.Ninth
                    //Element
                    O892H9      = L6Z561 + Y81986
                    //Element
                    U623RV.src  = L6Z561 + Y81986
                    //Element
                    if( sessionStorage.getItem( "Web_U22E35" ) ){
                        //Element
                        sessionStorage.removeItem( "Web_U22E35" )
                        //Element
                        sessionStorage.setItem( "Web_U22E35", G9G723.Ninth )
                    //Element
                    } else {
                        //Element
                        sessionStorage.setItem( "Web_U22E35", G9G723.Ninth )
                    }
                }
            })
        })
    }
    //Element
    N0V48A = (e) => {
        //Element
        const X48BR7 = async() => {
            //Element
            Axios.get( "https://api.renga.com.tr/bottle/category", {
                //Element
                params : {
                    //Element
                    ID : e.target.value
                }
            })
            //Element
            .then( D07762 => {
                //Element
                this.setState({
                    //Element
                    N6I51E : D07762.data
                })
                //Element
                if( D07762.statusText == "OK" ){
                    //Element
                    Axios.get( "https://api.renga.com.tr/volume/category", {
                        //Element
                        params : {
                            //Element
                            ID : e.target.value
                        }
                    })
                    //Element
                    .then( D07762 => {
                        //Element
                        this.setState({
                            //Element
                            F984US : D07762.data
                        })
                    })
                }
            })
        }
        //Element
        X48BR7()
    }
    //Element
    componentDidMount(){
        //Element
        this.R1995S()
        //Element
        this.V405J6()
        //Element
        this.R2A9Y9()
        //Element
        this.J535CY()
        //Element
        this.E467XE()
        //Element
        this.X084YW()
        //Element
        this.N644IV()
    }
    //Element
    render() {
        //Element
        let OD894D = this.state.OD894D
        //Element
        let D47X0N = new Date()
        //Element
        let N6E721 = D47X0N.toLocaleDateString( "tr-TR" )
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
                //Element
                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                    //Element
                    "Ürün"
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                    //Element
                    "Bilgi Güncelleme"
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
                //Element
                React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a" },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "ID"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_V180MF", name: "Web_V180MF", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: OD894D, type: "text", required: "required", disabled: "disabled" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Tarih"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_P65395", name: "Web_P65395", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: N6E721, type: "text", required: "required", disabled: "disabled" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Admin"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_Y71949", name: "Web_Y71949", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required", disabled: "disabled" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Unique ID"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_K4F83W", name: "Web_K4F83W", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Ürün Başlığı"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_U45Q45", name: "Web_U45Q45", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Alt Başlığı"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_LK55R0", name: "Web_LK55R0", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Kategori"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_GV376I", name: "Web_GV376I", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq", onChange: this.N0V48A },
                                    //Element
                                    this.state.LI259T.map( C608L9 => {
                                        //Element
                                        return React.createElement( "option", { key: C608L9.ID, value: C608L9.ID },
                                            //Element
                                            C608L9.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Matara"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_D642MB", name: "Web_D642MB", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                    //Element
                                    this.state.N6I51E.map( F0K9F0 => {
                                        //Element
                                        return React.createElement( "option", { key: F0K9F0.ID, value: F0K9F0.ID },
                                            //Element
                                            F0K9F0.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Hacim"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_T134PW", name: "Web_T134PW", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                    //Element
                                    this.state.F984US.map( KL3P80 => {
                                        //Element
                                        return React.createElement( "option", { key: KL3P80.ID, value: KL3P80.ID },
                                            //Element
                                            KL3P80.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Hacim m³"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_F3AG13", name: "Web_F3AG13", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Ağırlığı"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_M073CM", name: "Web_M073CM", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Adet"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_VT4J18", name: "Web_VT4J18", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Ambalaj"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_CP177E", name: "Web_CP177E", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Barkod"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_E684KO", name: "Web_E684KO", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 c21d8j" },
                                    //Element
                                    "Video"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_W34HX9", name: "Web_W34HX9", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Etiket"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_J812S4", name: "Web_J812S4", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Etiket"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_U422LT", name: "Web_U422LT", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Etiket"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_XD567D", name: "Web_XD567D", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 c21d8j" },
                                    //Element
                                    "Açıklama"
                                ),
                                //Element
                                React.createElement( CKEditor, { editor: ClassicEditor, data: this.state.K6L563 } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Renk"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_GO973H", name: "Web_GO973H", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                    //Element
                                    React.createElement( "option", { value: "0" },
                                        //Element
                                        "Lütfen Seçim Yapın"
                                    ),
                                    //Element
                                    this.state.DAR414.map( W709SA => {
                                        //Element
                                        return React.createElement( "option", { key: W709SA.ID, value: W709SA.ID },
                                            //Element
                                            W709SA.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 c21d8j" },
                                    //Element
                                    "Renk"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_EW280E", name: "Web_EW280E", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                    //Element
                                    React.createElement( "option", { value: "0" },
                                        //Element
                                        "Lütfen Seçim Yapın"
                                    ),
                                    //Element
                                    this.state.DAR414.map( H236V5 => {
                                        //Element
                                        return React.createElement( "option", { key: H236V5.ID, value: H236V5.ID },
                                            //Element
                                            H236V5.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 c21d8j" },
                                    //Element
                                    "Renk"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_R4399Q", name: "Web_R4399Q", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                    //Element
                                    React.createElement( "option", { value: "0" },
                                        //Element
                                        "Lütfen Seçim Yapın"
                                    ),
                                    //Element
                                    this.state.DAR414.map( W6S792 => {
                                        //Element
                                        return React.createElement( "option", { key: W6S792.ID, value: W6S792.ID },
                                            //Element
                                            W6S792.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Row, {},
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 kq045f", htmlFor: "Web_R494VD" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_R494VD", For: "Web_N87TJ3", SVG: "Web_V32KA9", Image: "Web_V5K852" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 kq045f", htmlFor: "Web_Y123I3" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_Y123I3", For: "Web_Y8BN79", SVG: "Web_QR150K", Image: "Web_XG45T3" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 kq045f", htmlFor: "Web_W5F6C8" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_W5F6C8", For: "Web_E2F4K5", SVG: "Web_Q0V66Y", Image: "Web_Y1V817" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_Z869M3" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_Z869M3", For: "Web_AE39H6", SVG: "Web_I082EZ", Image: "Web_C14B2O" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_J558OJ" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_J558OJ", For: "Web_F97XR5", SVG: "Web_I473TL", Image: "Web_W7IX78" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_Q071RS" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_Q071RS", For: "Web_PF0S40", SVG: "Web_Z0C098", Image: "Web_C08O21" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_V343CB" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_V343CB", For: "Web_D445HD", SVG: "Web_R453OQ", Image: "Web_OJ881E" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_B285F7" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_B285F7", For: "Web_Q47S38", SVG: "Web_N0434L", Image: "Web_J72SK4" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_T066I5" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_T066I5", For: "Web_T4JU45", SVG: "Web_T7F39L", Image: "Web_O80M2K" } )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                            //Element
                                            React.createElement( "button", { id: "Web_IF6E80", name: "Web_IF6E80", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: this.G7Z12B },
                                                //Element
                                                "Sil"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                            //Element
                                            React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: this.K37Z59 },
                                                //Element
                                                "Güncelle"
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Edit;