//Install
import React, { Component } from "react";
//Install
import Axios from "axios";
//Install
import { ReactComponent as P6920W } from "../../../Media/Icon/012.svg";
//Install
import { ReactComponent as AJ057Y } from "../../../Media/Icon/013.svg";
//Install
import propTypes from "prop-types";

//Element
class Image extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            I2DS09 : null,
            //Element
            S1I0F8 : null
        }
    }
    //Element
    componentDidMount(){
        //Element
        const { Image } = this.props
        //Element
        let EXP806      = document.getElementById( Image )
        //Element
        EXP806.classList.add( "d-none" )
    }
    //Element
    B1I11G = (e) => {
        //Element
        const { ID, For, SVG, Image } = this.props
        //Element
        const R3253V = new FormData()
        //Element
        const K05PK9 = document.getElementById( "Web_D845DF" )
        //Element
        let E6B63R   = document.getElementById( SVG )
        //Element
        let S0M96L   = document.getElementById( Image )
        //Element
        let Q8366Q   = document.getElementById( ID )
        //Element
        E6B63R.classList.add( "d-none" )
        //Element
        S0M96L.classList.remove( "d-none" )
        //Element
        let Y767G2 = document.getElementById( For )
        //Element
        if( e.target.files[0] != null ){
            //Element
            if( K05PK9 != null ){
                //Element
                K05PK9.disabled = true
            }
            //Element
            this.setState({
                //Element
                I2DS09 : e.target.files[0],
                //Element
                S1I0F8 : e.target.files[0].name
            })
            //Element
            let EU25O7 = URL.createObjectURL( e.target.files[0] )
            //Element
            Q8366Q.src = EU25O7
            //Element
            R3253V.append( "image", e.target.files[0] )
            //Element
            if( ID == "Web_R494VD" ){
                //Element
                Axios.post( "https://api.renga.com.tr/upload", R3253V )
                //Element
                .then( EK32Q6 => {
                    //Element
                    sessionStorage.removeItem( "Web_L3N3U8" )
                    //Element
                    sessionStorage.setItem( "Web_L3N3U8", EK32Q6.data )
                    //Element
                    if( EK32Q6.statusText == "OK" ){
                        //Element
                        if( K05PK9 != null ){
                            //Element
                            K05PK9.disabled = false
                        }
                    }
                })
            //Element
            } else if( ID == "Web_Y123I3" ){
                //Element
                Axios.post( "https://api.renga.com.tr/upload", R3253V )
                //Element
                .then( QQ881V => {
                    //Element
                    sessionStorage.removeItem( "Web_N7O7F6" )
                    //Element
                    sessionStorage.setItem( "Web_N7O7F6", QQ881V.data )
                    //Element
                    if( QQ881V.statusText == "OK" ){
                        //Element
                        if( K05PK9 != null ){
                            //Element
                            K05PK9.disabled = false
                        }
                    }
                })
            //Element
            } else if( ID == "Web_W5F6C8" ){
                //Element
                Axios.post( "https://api.renga.com.tr/upload", R3253V )
                //Element
                .then( HW138D => {
                    //Element
                    sessionStorage.removeItem( "Web_Y748IW" )
                    //Element
                    sessionStorage.setItem( "Web_Y748IW", HW138D.data )
                    //Element
                    if( HW138D.statusText == "OK" ){
                        //Element
                        if( K05PK9 != null ){
                            //Element
                            K05PK9.disabled = false
                        }
                    }
                })
            //Element
            } else if( ID == "Web_Z869M3" ){
                //Element
                Axios.post( "https://api.renga.com.tr/upload", R3253V )
                //Element
                .then( YD068O => {
                    //Element
                    sessionStorage.removeItem( "Web_K4J317" )
                    //Element
                    sessionStorage.setItem( "Web_K4J317", YD068O.data )
                    //Element
                    if( YD068O.statusText == "OK" ){
                        //Element
                        if( K05PK9 != null ){
                            //Element
                            K05PK9.disabled = false
                        }
                    }
                })
            //Element
            } else if( ID == "Web_J558OJ" ){
                //Element
                Axios.post( "https://api.renga.com.tr/upload", R3253V )
                //Element
                .then( G635MU => {
                    //Element
                    sessionStorage.removeItem( "Web_D3842S" )
                    //Element
                    sessionStorage.setItem( "Web_D3842S", G635MU.data )
                    //Element
                    if( G635MU.statusText == "OK" ){
                        //Element
                        if( K05PK9 != null ){
                            //Element
                            K05PK9.disabled = false
                        }
                    }
                })
            //Element
            } else if( ID == "Web_Q071RS" ){
                //Element
                Axios.post( "https://api.renga.com.tr/upload", R3253V )
                //Element
                .then( NN08W6 => {
                    //Element
                    sessionStorage.removeItem( "Web_L1D37N" )
                    //Element
                    sessionStorage.setItem( "Web_L1D37N", NN08W6.data )
                    //Element
                    if( NN08W6.statusText == "OK" ){
                        //Element
                        if( K05PK9 != null ){
                            //Element
                            K05PK9.disabled = false
                        }
                    }
                })
            //Element
            } else if( ID == "Web_V343CB" ){
                //Element
                Axios.post( "https://api.renga.com.tr/upload", R3253V )
                //Element
                .then( G5L53P => {
                    //Element
                    sessionStorage.removeItem( "Web_YN2X81" )
                    //Element
                    sessionStorage.setItem( "Web_YN2X81", G5L53P.data )
                    //Element
                    if( G5L53P.statusText == "OK" ){
                        //Element
                        if( K05PK9 != null ){
                            //Element
                            K05PK9.disabled = false
                        }
                    }
                })
            //Element
            } else if( ID == "Web_B285F7" ){
                //Element
                Axios.post( "https://api.renga.com.tr/upload", R3253V )
                //Element
                .then( G5163L => {
                    //Element
                    sessionStorage.removeItem( "Web_CXO598" )
                    //Element
                    sessionStorage.setItem( "Web_CXO598", G5163L.data )
                    //Element
                    if( G5163L.statusText == "OK" ){
                        //Element
                        if( K05PK9 != null ){
                            //Element
                            K05PK9.disabled = false
                        }
                    }
                })
            //Element
            } else if( ID == "Web_T066I5" ){
                //Element
                Axios.post( "https://api.renga.com.tr/upload", R3253V )
                //Element
                .then( I65F49 => {
                    //Element
                    sessionStorage.removeItem( "Web_U22E35" )
                    //Element
                    sessionStorage.setItem( "Web_U22E35", I65F49.data )
                    //Element
                    if( I65F49.statusText == "OK" ){
                        //Element
                        if( K05PK9 != null ){
                            //Element
                            K05PK9.disabled = false
                        }
                    }
                })
            }
        }
    }
    //Element
    render() {
        //Element
        const { ID, For, SVG, Image } = this.props
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 ix0q18" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 d8z4l3" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 r68ub7" },
                    //Element
                    React.createElement( "div", { id: SVG, className: "float-start w-auto p-0 m-0 s905gi" },
                        //Element
                        React.createElement( P6920W, {} )
                    ),
                    //Element
                    React.createElement( "div", { id: Image, className: "float-start w-auto p-0 m-0 klf458" },
                        //Element
                        React.createElement( "img", { id: ID, alt: "Fotoğraf", title: "Fotoğraf", src: "", width: "200", height: "auto" } )
                    ),
                    //Element
                    React.createElement( "label", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute rounded-circle f5f48j", htmlFor: For },
                        //Element
                        React.createElement( AJ057Y, {} ),
                        //Element
                        React.createElement( "input", { id: For, name: For, className: "d-none", type: "file", accept: ".png, .jpg, .jpeg", onChange: this.B1I11G} )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Image.propTypes = {
    ID    : propTypes.string.isRequired,
    For   : propTypes.string.isRequired,
    SVG   : propTypes.string.isRequired,
    Image : propTypes.string.isRequired
}
//Element
export default Image;