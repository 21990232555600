//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import { CKEditor } from "@ckeditor/ckeditor5-react";
//Install
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//Element
function O4I96S(e){ 
    //Element
    e.preventDefault()
    //Element
    let G3G32S, QJ978R, O4V12G, Q81Y24, Z56U0U, X333J9, E715HS
    //Element
    let R7BZ46 = new Date()
    //Element
    let G9314T = R7BZ46.toLocaleDateString( "tr-TR" )
    //Element
    G3G32S = document.getElementById( "Web_H205J1" ).value
    //Element
    QJ978R = document.getElementById( "Web_V585WQ" ).value
    //Element
    O4V12G = document.getElementById( "Web_X6R96P" ).value
    //Element
    Q81Y24 = document.getElementsByClassName( "ck-editor__editable_inline" )[0].innerHTML
    //Element
    Z56U0U = sessionStorage.getItem( "Web_K6KU98" )
    //Element
    X333J9 = document.getElementById( "Web_Z1069S" ).value
    //Element
    E715HS = document.getElementById( "Web_UL698H" ).value
    //Element
    const B43P41 = async() => {
        //Element
        const B382CT = await Axios.get( "https://api.renga.com.tr/blog/update", {
            //Element
            params : {
                //Element
                ID       : G3G32S,
                //Element
                Title    : O4V12G,
                //Element
                Summary  : QJ978R,
                //Element
                Content  : Q81Y24,
                //Element
                Image    : Z56U0U,
                //Element
                Date     : G9314T,
                //Element
                Category : X333J9,
                //Element
                Author   : E715HS
            }
        })
        //Element
        .catch( RL0650 => {
            //Element
            console.log( RL0650 )
        })
    }
    //Element
    B43P41()
    //Element
    window.location.href = "/blog-order"
}
//Element
function W76780(e){ 
    //Element
    e.preventDefault()
    //Element
    let G7G78V = document.getElementById( "Web_H205J1" ).value
    //Element
    const V7578Y = async() => {
        //Element
        const O3835Z = await Axios.get( "https://api.renga.com.tr/blog/delete", {
            //Element
            params : {
                //Element
                ID : G7G78V
            }
        })
        //Element
        .catch( KOY294 => {
            //Element
            console.log( KOY294 )
        })
    }
    //Element
    V7578Y()
    //Element
    window.location.href = "/blog-order"
}
//Element
function Edit(){
    //Element
    const { ID } = useParams()
    //Element
    let D11RO6 = "https://api.renga.com.tr/images/"
    //Element
    let CB863Z, Q2H83G, L006T2, V0Z437, VE3I73, LU29Y5
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const B92L7D = async() => {
            //Element
            const I3901S = await Axios.get( "https://api.renga.com.tr/blog/select", {
                //Element
                params : {
                    //Element
                    ID : ID,
                }
            } )
            //Element
            C68006( I3901S.data )
        }
        //Element
        B92L7D()
    }, [] )
    //Element
    G7TI26.map( (G4I224) => {
        //Element
        CB863Z = G4I224.Title
        //Element
        Q2H83G = G4I224.Summary
        //Element
        L006T2 = G4I224.Content
        //Element
        V0Z437 = G4I224.Image
        //Element
        VE3I73 = G4I224.Date
        //Element
        LU29Y5 = G4I224.Category
    })
    //Element
    useEffect( () => {
        //Element
        let K7A5O2   = D11RO6 + V0Z437
        //Element
        const D8HC35 = document.querySelector( "#Web_N28JS9" )
        //Element
        D8HC35.src   = K7A5O2
        //Element
        document.getElementsByClassName( "klf458" )[0].classList.remove( "d-none" )
        //Element
        document.getElementsByClassName( "p43687" )[0].classList.add( "d-none" )
    })
    //Element
    sessionStorage.setItem( "Web_K6KU98", V0Z437 )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Blog"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Bilgi Günccelleme"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "ID"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_H205J1", name: "Web_H205J1", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Tarih"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_EK8663", name: "Web_EK8663", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk",  defaultValue: VE3I73, type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Yazar"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_UL698H", name: "Web_UL698H", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: "Renga.com.tr", type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Blog Başlığı"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_X6R96P", name: "Web_X6R96P", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: CB863Z, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Özet Bilgi"
                            ),
                            //Element
                            React.createElement( "textarea", { id: "Web_V585WQ", name: "Web_V585WQ", className: "float-start w-100 p-0 ps-3 pe-3 pt-3 pb-3 m-0 mt-2 a49170", defaultValue: Q2H83G, rows: "5", cols: "20", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Bilgilendirme"
                            ),
                            //Element
                            React.createElement( CKEditor, { editor: ClassicEditor, data: L006T2 } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Kategori"
                            ),
                            //Element
                            React.createElement( "select", { id: "Web_Z1069S", name: "Web_Z1069S", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                //Element
                                React.createElement( "option", { value: "Blog" },
                                    //Element
                                    "Blog"
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                    //Element
                                    React.createElement( "button", { id: "Web_IF6E80", name: "Web_IF6E80", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: W76780 },
                                        //Element
                                        "Sil"
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                    //Element
                                    React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: O4I96S },
                                        //Element
                                        "Güncelle"
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;