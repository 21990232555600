//Install
import React from "react";
//Install
import Axios from "axios";
//Install
import { Container, Row, Col } from "react-bootstrap";

//Element
function K066FB(e){
    //Element
    e.preventDefault()
    //Element
    let W355CX, G87R5N, SMW907, CE026S, J4671Y, Q802NK
    //Element
    W355CX = document.getElementById( "Web_G64R9F" ).value
    //Element
    G87R5N = document.getElementById( "Web_P9340F" ).value
    //Element
    SMW907 = sessionStorage.getItem( "Web_N748Z0" )
    //Element
    CE026S = document.getElementById( "Web_N5X6E9" ).files.length
    //Element
    J4671Y = document.getElementsByClassName( "p43687" )[0]
    //Element
    Q802NK = document.getElementsByClassName( "uh0823" )[0]
    //Element
    if( CE026S == "0" ){
        //Element
        J4671Y.style.border = "1px solid #1B84FF"
        //Element
        Q802NK.innerHTML    = "Lütfen Fotoğraf Seçin."
        //Element
        J4671Y.focus()
    //Element
    } else {
        //Element
        J4671Y.style.border = "1px solid #FFFFFF"
        //Element
        const A51F8A = async() => {
            //Element
            const VV21D6 = await Axios.get( "https://api.renga.com.tr/document/add", {
                //Element
                params : {
                    //Element
                    Title   : W355CX,
                    //Element
                    Summary : G87R5N,
                    //Element
                    Image   : SMW907
                }
            })
            //Element
            .catch( A0H77H => {
                //Element
                console.log( A0H77H )
            })
        }
        //Element
        A51F8A()
        //Element
        window.location.href = "/document-order"
    }
}
//Element
function New(){
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Belge"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Yeni Ekle"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: K066FB },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "ID"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_W15250", name: "Web_W15250", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: "0", type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Belge Başlığı"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_G64R9F", name: "Web_G64R9F", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Bilgilendirme"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_P9340F", name: "Web_P9340F", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                        //Element
                        React.createElement( "button", { id: "Web_Q24564", name: "Web_Q24564", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                            //Element
                            "Gönder"
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default New;