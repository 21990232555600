//Install
import React from "react";
//Install
import Axios from "axios";

//Element
function B07603(e){
    //Element
    e.preventDefault()
    //Element
    let M603WX, WR2X98
    //Element
    M603WX = document.getElementById( "Web_N0J37O" ).value
    //Element
    WR2X98 = document.getElementById( "Web_C600RC" ).value
    //Element
    const SZ74P4 = async() => {
        //Element
        const SY2663 = await Axios.get( "https://api.renga.com.tr/variety/add", {
            //Element
            params : {
                //Element
                Title : M603WX,
                //Element
                Date  : WR2X98
            }
        })
        //Element
        .catch( S294Y5 => {
            //Element
            console.log( S294Y5 )
        })
    }
    //Element
    SZ74P4()
    //Element
    window.location.href = "/variety"
}
//Element
function New(){
    //Element
    let Z6W68B = new Date()
    //Element
    let N3S87W = Z6W68B.toLocaleDateString( "tr-TR" )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Çeşitlilik"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Yeni Ekle"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: B07603 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Kategori ID"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_B1947A", name: "Web_B1947A", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: "0", type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Kategori Adı"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_N0J37O", name: "Web_N0J37O", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Tarih"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_C600RC", name: "Web_C600RC", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: N3S87W, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                    //Element
                    React.createElement( "button", { id: "Web_C8U6K6", name: "Web_C8U6K6", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                        //Element
                        "Gönder"
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default New;