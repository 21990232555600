//Install
import React, { useState, useEffect } from "react";
//Install
import Axios from "axios";

//Element
function N348R2(e){
    //Element
    e.preventDefault()
    //Element
    let SG113D, JVY456, F3028O
    //Element
    SG113D = document.getElementById( "Web_N714AK" ).value
    //Element
    JVY456 = document.getElementById( "Web_N3Q236" ).value
    //Element
    F3028O = document.getElementById( "Web_R365S9" ).value
    //Element
    const A4695U = async() => {
        //Element
        const SJ10L8 = await Axios.get( "https://api.renga.com.tr/volume/add", {
            //Element
            params : {
                //Element
                Title    : SG113D,
                //Element
                Category : JVY456,
                //Element
                Date     : F3028O
            }
        })
        //Element
        .catch( KN31A7 => {
            //Element
            console.log( KN31A7 )
        })
    }
    //Element
    A4695U()
    //Element
    window.location.href = "/volume"
}
//Element
function New(){
    //Element
    let V8X74N = new Date()
    //Element
    let B8D796 = V8X74N.toLocaleDateString( "tr-TR" )
    //Element
    const [ D79551, IK946T ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const M10268 = async() => {
            //Element
            const NM9557 = await Axios.get( "https://api.renga.com.tr/variety", {} )
            //Element
            IK946T( NM9557.data )
        }
        //Element
        M10268()
    }, [] )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Hacim"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Yeni Ekle"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", onSubmit: N348R2 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Hacim ID"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_N9791J", name: "Web_N9791J", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: "0", type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Hacim Adı"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_N714AK", name: "Web_N714AK", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Kategori"
                    ),
                    //Element
                    React.createElement( "select", { id: "Web_N3Q236", name: "Web_N3Q236", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                        //Element
                        D79551.map( (H3419W) => {
                            //Element
                            return React.createElement( "option", { key: H3419W.ID, value: H3419W.ID },
                                //Element
                                H3419W.Title
                            )
                        })
                    )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Tarih"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_R365S9", name: "Web_R365S9", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: B8D796, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                    //Element
                    React.createElement( "button", { id: "Web_QP779M", name: "Web_QP779M", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                        //Element
                        "Gönder"
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default New;