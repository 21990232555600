//Install
import React, { useEffect } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Card from "./Card";
//Install
import Blog from "./Blog";
//Install
import Header from "../Header";
//Install
import Footer from "../Footer";
//Install
import Product from "./Product";
//Install
import Factory from "./Factory";
//Install
import Document from "./Document";

//Element
function Q192Y0(){
    //Element
    document.title = "Merhaba : Renga - Renga.com.tr"
}
//Element
function Default(){
    //Element
    Q192Y0()
    //Element
    let YS014L = [ "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık" ]
    //Element
    let E34245 = new Date()
    //Element
    let E89J9A = E34245.getDate()
    //Element
    let L0047O = YS014L[ E34245.getMonth() ]
    //Element
    let H8E93O = E34245.getFullYear()
    //Element
    let R991JQ = E34245.getHours()
    //Element
    let A4621K = E34245.getMinutes()
    //Element
    let B348QM = ":"
    //Element
    let P10047 = " "
    //Element
    let D767O5 = E89J9A + P10047 + L0047O + P10047 + H8E93O + P10047 + R991JQ + B348QM + A4621K
    //Element
    const [ R800OV, N574PR ] = React.useState([])
    const [ O2L84Y, G428G5 ] = React.useState([])
    const [ F575WA, N29QZ1 ] = React.useState([])
    const [ U4Z86I, Y0I18B ] = React.useState([])
    const [ XZ5L46, K73IS4 ] = React.useState([])
    const [ M38D81, D2008W ] = React.useState([])
    //Element
    const [ N486E5, PU0D97 ] = React.useState([])
    const [ DHV502, KHP407 ] = React.useState([])
    const [ J5W056, QP306Y ] = React.useState([])
    const [ G19801, S50FO1 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const DHU834 = async() => {
            //Element
            const B02N29 = await Axios.get( "https://api.renga.com.tr/product/count" )
            //Element
            .then( I1P869 => {
                //Element
                N574PR( I1P869.data )
            })
            //Element
            const X63M8L = await Axios.get( "https://api.renga.com.tr/blog/count" )
            //Element
            .then( R04M41 => {
                //Element
                G428G5( R04M41.data )
            })
            //Element
            const Y357KP = await Axios.get( "https://api.renga.com.tr/document/count" )
            //Element
            .then( P2T86D => {
                //Element
                N29QZ1( P2T86D.data )
            })
            //Element
            const S4C5F0 = await Axios.get( "https://api.renga.com.tr/prize/count" )
            //Element
            .then( KT4100 => {
                //Element
                Y0I18B( KT4100.data )
            })
            //Element
            const S3550N = await Axios.get( "https://api.renga.com.tr/factory/count" )
            //Element
            .then( X79GZ5 => {
                //Element
                K73IS4( X79GZ5.data )
            })
            //Element
            const Z501NX = await Axios.get( "https://api.renga.com.tr/reference/count" )
            //Element
            .then( K6R19Y => {
                //Element
                D2008W( K6R19Y.data )
            })
            //Element
            const J6C4L4 = await Axios.get( "https://api.renga.com.tr/product/order" )
            //Element
            .then( Y96086 => {
                //Element
                PU0D97( Y96086.data )
            })
            //Element
            const PW876I = await Axios.get( "https://api.renga.com.tr/blog/order" )
            //Element
            .then( J4T28V => {
                //Element
                KHP407( J4T28V.data )
            })
            //Element
            const P730VS = await Axios.get( "https://api.renga.com.tr/factory/order" )
            //Element
            .then( I5224S => {
                //Element
                QP306Y( I5224S.data )
            })
            //Element
            const S8T76X = await Axios.get( "https://api.renga.com.tr/document/order" )
            //Element
            .then( YN0219 => {
                //Element
                S50FO1( YN0219.data )
            })
        }
        //Element
        DHU834()
    }, [] )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v9339k" },
        //Element
        React.createElement( Header, { ID: "0", Title: "Merhaba", Status: D767O5 } ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 q0r45m" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        R800OV.map( (YGH475) => {
                            //Element
                            return React.createElement( Card, { key: YGH475.Count, ID: YGH475.Count.toString(), Title: "Ürün", Target: "/" } )
                        })
                    ),
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        O2L84Y.map( (O2IL88) => {
                            //Element
                            return React.createElement( Card, { key: O2IL88.Count, ID: O2IL88.Count.toString(), Title: "Blog", Target: "/" } )
                        })
                    ),
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        F575WA.map( (H36A5F) => {
                            //Element
                            return React.createElement( Card, { key: H36A5F.Count, ID: H36A5F.Count.toString(), Title: "Belge", Target: "/" } )
                        })
                    ),
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        U4Z86I.map( (W4U22A) => {
                            //Element
                            return React.createElement( Card, { key: W4U22A.Count, ID: W4U22A.Count.toString(), Title: "Ödül", Target: "/" } )
                        })
                    ),
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        XZ5L46.map( (WP1079) => {
                            //Element
                            return React.createElement( Card, { key: WP1079.Count, ID: WP1079.Count.toString(), Title: "Fotoğraf", Target: "/" } )
                        })
                    ),
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        M38D81.map( (C63Y42) => {
                            //Element
                            return React.createElement( Card, { key: C63Y42.Count, ID: C63Y42.Count.toString(), Title: "Referans", Target: "/" } )
                        })
                    )
                )
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 e279bh" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:8 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-5 pb-4 m-0 mt-4 w60023" },
                            //Element
                            React.createElement( "h3", { className: "float-start w-100 p-0 pb-3 m-0 k3gq53" },
                                //Element
                                "Ürünler"
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 z336ry" },
                                //Element
                                N486E5.map( (S89T61) => {
                                    //Element
                                    return React.createElement( Product, { key: S89T61.ID, ID: S89T61.ID.toString(), Title: S89T61.Title, Time: S89T61.Date, Image: S89T61.Image, Target: S89T61.ID.toString() } )
                                })
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-5 pb-4 m-0 mt-4 w60023" },
                            //Element
                            React.createElement( "h3", { className: "float-start w-100 p-0 pb-3 m-0 k3gq53" },
                                //Element
                                "Blog"
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 z336ry" },
                                //Element
                                DHV502.map( (K110QG) => {
                                    //Element
                                    return React.createElement( Blog, { key: K110QG.ID, ID: K110QG.ID.toString(), Title: K110QG.Title, Time: K110QG.Date, Image: K110QG.Image, Target: K110QG.ID.toString() } )
                                })
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-5 pb-4 m-0 mt-4 b129uj" },
                            //Element
                            React.createElement( "h3", { className: "float-start w-100 p-0 pb-3 m-0 el8h94" },
                                //Element
                                "Fabrikamız"
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 w123l4" },
                                //Element
                                J5W056.map( (HT0300) => {
                                    //Element
                                    return React.createElement( Factory, { key: HT0300.ID, ID: HT0300.ID.toString(), Title: HT0300.Title, Image: HT0300.Image, Target: "/" } )
                                })
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-5 m-0 mt-4 b129uj" },
                            //Element
                            React.createElement( "h3", { className: "float-start w-100 p-0 pb-3 m-0 el8h94" },
                                //Element
                                "Belgelerimiz"
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 w123l4" },
                                //Element
                                G19801.map( (H6548B) => {
                                    //Element
                                    return React.createElement( Document, { key: H6548B.ID, ID: H6548B.ID.toString(), Title: H6548B.Title, Image: H6548B.Image, Target: "/" } )
                                })
                            )
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( Footer, {} )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Default;