//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function L9414V(e){ 
    //Element
    e.preventDefault()
    //Element
    let E161MZ, LV2092, R10YM7
    //Element
    E161MZ = document.getElementById( "Web_F7435V" ).value
    //Element
    LV2092 = document.getElementById( "Web_AM88Z9" ).value
    //Element
    R10YM7 = document.getElementById( "Web_R4936B" ).value
    //Element
    const I7816P = async() => {
        //Element
        const BD852E = await Axios.get( "https://api.renga.com.tr/status/update", {
            //Element
            params : {
                //Element
                ID     : E161MZ,
                //Element
                Title  : LV2092,
                //Element
                Status : R10YM7
            }
        })
        //Element
        .catch( DY3S23 => {
            //Element
            console.log( DY3S23 )
        })
    }
    //Element
    I7816P()
    //Element
    window.location.href = "/status"
}
//Element
function U590P7(e){ 
    //Element
    e.preventDefault()
    //Element
    let TW818D = document.getElementById( "Web_F7435V" ).value
    //Element
    const RQX421 = async() => {
        //Element
        const F03H5Y = await Axios.get( "https://api.renga.com.tr/status/delete", {
            //Element
            params : {
                //Element
                ID : TW818D
            }
        })
        //Element
        .catch( O5K99K => {
            //Element
            console.log( O5K99K )
        })
    }
    //Element
    RQX421()
    //Element
    window.location.href = "/status"
}
//Element
function Edit(){
    //Element
    const { ID } = useParams()
    //Element
    let W704G4, N794HW
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const C288V7 = async() => {
            //Element
            const E132H0 = await Axios.get( "https://api.renga.com.tr/status/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            } )
            //Element
            C68006( E132H0.data )
        }
        //Element
        C288V7()
    }, [] )
    //Element
    G7TI26.map( (L15PI7) => {
        //Element
        W704G4 = L15PI7.Title
        //Element
        N794HW = L15PI7.Status
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Başvuru Yapılacak Bölümler"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Bilgi Düzenleme"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "ID"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_F7435V", name: "Web_F7435V", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Adı"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_AM88Z9", name: "Web_AM88Z9", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: W704G4, type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Durum"
                    ),
                    //Element
                    React.createElement( "select", { id: "Web_R4936B", name: "Web_R4936B", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq", defaultValue: N794HW },
                        //Element
                        React.createElement( "option", { value: "1" },
                            //Element
                            "Aktif"
                        ),
                        //Element
                        React.createElement( "option", { value: "0" },
                            //Element
                            "Pasif"
                        )
                    )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_U70C69", name: "Web_U70C69", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: U590P7 },
                                    //Element
                                    "Sil"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_B87743", name: "Web_B87743", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: L9414V },
                                    //Element
                                    "Güncelle"
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;