//Install
import React, { useState, useEffect } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Header from "../../Header";
//Install
import Footer from "../../Footer";

//Element
function A5L82C(){
    //Element
    document.title = "Merhaba : Renga - Renga.com.tr"
}
//Element
function H0AT52(e){
    //Element
    e.preventDefault()
    //Element
    let D2O5R6, O03674, X96KT1, L4563A, C63V36
    //Element
    D2O5R6 = document.getElementById( "Web_Q9E75Z" ).value
    //Element
    O03674 = document.getElementById( "Web_P543KJ" ).value
    //Element
    X96KT1 = document.getElementById( "Web_MW2845" ).value
    //Element
    L4563A = document.getElementById( "Web_L6MR97" ).value
    //Element
    C63V36 = document.getElementById( "Web_HQ5169" ).value
    //Element
    const P0I838 = async() => {
        //Element
        const C0M61O = await Axios.get( "https://api.renga.com.tr/social/update", {
            //Element
            params : {
                //Element
                Facebook  : D2O5R6,
                //Element
                Twitter   : O03674,
                //Element
                Instagram : X96KT1,
                //Element
                Youtube   : L4563A,
                //Element
                Linkedin  : C63V36
            }
        })
        //Element
        .catch( F9X18L => {
            //Element
            console.log( F9X18L )
        })
    }
    //Element
    P0I838()
    //Element
    window.location.href = "/social"
}
//Element
function Default(){
    //Element
    A5L82C()
    //Element
    let S4X73M, D3H30U, J274Y2, Q369TH, OX2937, E860AQ
    //Element
    let YS014L = [ "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık" ]
    //Element
    let E34245 = new Date()
    //Element
    let E89J9A = E34245.getDate()
    //Element
    let L0047O = YS014L[ E34245.getMonth() ]
    //Element
    let H8E93O = E34245.getFullYear()
    //Element
    let R991JQ = E34245.getHours()
    //Element
    let A4621K = E34245.getMinutes()
    //Element
    let B348QM = ":"
    //Element
    let P10047 = " "
    //Element
    let D767O5 = E89J9A + P10047 + L0047O + P10047 + H8E93O + P10047 + R991JQ + B348QM + A4621K
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const H09SB6 = async() => {
            //Element
            const Z8YB17 = await Axios.get( "https://api.renga.com.tr/social", {} )
            //Element
            C68006( Z8YB17.data )
        }
        //Element
        H09SB6()
    }, [] )
    //Element
    G7TI26.map( (M00521) => {
        //Element
        S4X73M = M00521.ID
        //Element
        D3H30U = M00521.Facebook
        //Element
        J274Y2 = M00521.Twitter
        //Element
        Q369TH = M00521.Instagram
        //Element
        OX2937 = M00521.Youtube
        //Element
        E860AQ = M00521.Linkedin
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v9339k" },
        //Element
        React.createElement( Header, { ID: "0", Title: "Merhaba", Status: D767O5 } ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ga8x38" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                                    //Element
                                    "Medya"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                                    //Element
                                    "Bilgi Düzenleme"
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
                                //Element
                                React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: H0AT52 },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "ID"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_C2U3P7", name: "Web_C2U3P7", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: S4X73M, type: "text", required: "required", disabled: "disabled" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Facebook"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_Q9E75Z", name: "Web_Q9E75Z", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: D3H30U, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Twitter"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_P543KJ", name: "Web_P543KJ", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: J274Y2, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Instagram"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_MW2845", name: "Web_MW2845", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Q369TH, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Youtube"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_L6MR97", name: "Web_L6MR97", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: OX2937, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Linkedin"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_HQ5169", name: "Web_HQ5169", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: E860AQ, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                            //Element
                                            React.createElement( "button", { id: "Web_L9Y32S", name: "Web_L9Y32S", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                                                //Element
                                                "Gönder"
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( Footer, {} )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Default;