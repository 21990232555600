//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as W6L568 } from "../../Media/Icon/011.svg";
//Install
import propTypes from "prop-types";

//Element
class Document extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Image, Target } = this.props
        //Element
        let W7004Z = "https://api.renga.com.tr/images/"
        //Element
        let K2984A = "/document-edit/"
        //Element
        let DA801V = W7004Z + Image
        //Element
        let TU212M = K2984A + ID
        //Element
        const Content = React.createElement( Row, {},
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-2 mb-2 f35qk4" },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 g24k5j" },
                        //Element
                        React.createElement( "img", { alt: Title, title: Title, src: DA801V, width: "55", height: "auto" } )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:6 },
                //Element
                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 mt-2 mb-2 j7311w" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-truncate lh-1 k7y1x5" },
                        //Element
                        Title
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-2 mb-2 gn9r17" },
                    //Element
                    React.createElement( "a", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 t3405h", href: TU212M },
                        //Element
                        React.createElement( W6L568, {} )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Document.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Image  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Document;