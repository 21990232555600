//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function K2Z885(e){ 
    //Element
    e.preventDefault()
    //Element
    let Y7B2B5, WF34Y5, P33V07, YD2X11
    //Element
    Y7B2B5 = document.getElementById( "Web_F7435V" ).value
    //Element
    WF34Y5 = document.getElementById( "Web_AM88Z9" ).value
    //Element
    P33V07 = document.getElementById( "Web_Z0TZ30" ).value
    //Element
    YD2X11 = document.getElementById( "Web_I63G26" ).value
    //Element
    const P111LT = async() => {
        //Element
        const U0952N = await Axios.get( "https://api.renga.com.tr/color/update", {
            //Element
            params : {
                //Element
                ID    : Y7B2B5,
                //Element
                Title : WF34Y5,
                //Element
                RGB   : P33V07,
                //Element
                Date  : YD2X11
            }
        })
        //Element
        .catch( K5G947 => {
            //Element
            console.log( K5G947 )
        })
    }
    //Element
    P111LT()
    //Element
    window.location.href = "/color"
}
//Element
function V578MY(e){ 
    //Element
    e.preventDefault()
    //Element
    let R88T7U = document.getElementById( "Web_F7435V" ).value
    //Element
    const WK363J = async() => {
        //Element
        const G6H4U9 = await Axios.get( "https://api.renga.com.tr/color/delete", {
            //Element
            params : {
                //Element
                ID : R88T7U
            }
        })
        //Element
        .catch( O56913 => {
            //Element
            console.log( O56913 )
        })
    }
    //Element
    WK363J()
    //Element
    window.location.href = "/color"
}
//Element
function Edit(){
    //Element
    let Q6YC07 = new Date()
    //Element
    let EV7668 = Q6YC07.toLocaleDateString( "tr-TR" )
    //Element
    const { ID } = useParams()
    //Element
    let G46X2U, N8L29T
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const Q9W54G = async() => {
            //Element
            const C71S5J = await Axios.get( "https://api.renga.com.tr/color/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            })
            //Element
            C68006( C71S5J.data )
        }
        //Element
        Q9W54G()
    }, [] )
    //Element
    G7TI26.map( (RN4Q36) => {
        //Element
        G46X2U = RN4Q36.Title
        //Element
        N8L29T = RN4Q36.RGB
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Renk"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Bilgi Güncelleme"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Renk ID"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_F7435V", name: "Web_F7435V", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Renk Adı"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_AM88Z9", name: "Web_AM88Z9", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: G46X2U, type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "RGB"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_Z0TZ30", name: "Web_Z0TZ30", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: N8L29T, type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Tarih"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_I63G26", name: "Web_I63G26", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: EV7668, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_U70C69", name: "Web_U70C69", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: V578MY },
                                    //Element
                                    "Sil"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_B87743", name: "Web_B87743", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: K2Z885 },
                                    //Element
                                    "Güncelle"
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;