//Install
import React, { useState, useEffect } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as R8HJ67 } from "../../../Media/Icon/011.svg";
//Install
import Axios from "axios";

//Element
function List(){
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const W1M030 = async() => {
            //Element
            const H3Z988 = await Axios.get( "https://api.renga.com.tr/color", {} )
            //Element
            C68006( H3Z988.data )
        }
        //Element
        W1M030()
    }, [] )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Renk"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Tam Liste"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 sh874h" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 n2b78e" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d9287e" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 po732b" },
                                //Element
                                "Renk ID"
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d9287e" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 po732b" },
                                //Element
                                "Renk Adı"
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d9287e" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 po732b" },
                                //Element
                                "RGB"
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d9287e" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 po732b" },
                                //Element
                                ""
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "ol", { className: "float-start w-100 p-0 m-0 p4s8r8" },
                //Element
                G7TI26.map( (ZCM841) => {
                    //Element
                    let G286X9 = "/color-update/"
                    //Element
                    let QN71T9 = G286X9 + ZCM841.ID
                    //Element
                    return React.createElement( "li", { key: ZCM841.ID, className: "float-start w-100 p-0 m-0" },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 jl002z" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-auto p-0 m-0 text-truncate text-center" },
                                        //Element
                                        ZCM841.ID
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 jl002z" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-truncate" },
                                        //Element
                                        ZCM841.Title
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 jl002z" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-truncate" },
                                        //Element
                                        ZCM841.RGB
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-3 jl002z" },
                                    //Element
                                    React.createElement( "a", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 text-truncate", href: QN71T9 },
                                        //Element
                                        React.createElement( R8HJ67, {} )
                                    )
                                )
                            )
                        )
                    )
                })
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default List;