//Install
import React, { Component } from "react";
//Install
import Axios from "axios";
//Install
import { ReactComponent as GF917T } from "../../../Media/Icon/012.svg";
//Install
import { ReactComponent as UTT059 } from "../../../Media/Icon/013.svg";

//Element
class Upload extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            N586HZ : null,
            //Element
            Q66D65 : null
        }
    }
    //Element
    A503G4 = (e) => {
        //Element
        const W371FK    = new FormData()
        //Element
        let S3YJ52      = document.getElementsByClassName( "p43687" )[0]
        //Element
        let OC6668      = document.querySelector( ".f5f48j" )
        //Element
        let K59ZS2      = document.getElementsByClassName( "klf458" )[0]
        //Element
        let V8979K      = document.getElementById( "Web_S696YN" )
        //Element
        let K92132      = document.getElementsByClassName( "uh0823" )[0]
        //Element
        let J015SS      = document.getElementById( "Web_V4Z90F" )
        //Element
        let JUL570      = document.getElementById( "Web_N7G0G4" )
        //Element
        if( J015SS != null ){
            //Element
            J015SS.disabled = true
        }
        //Element
        if( JUL570 != null ){
            //Element
            JUL570.disabled = true
        }
        //Element
        S3YJ52.classList.add( "d-none" )
        //Element
        K59ZS2.classList.remove( "d-none" )
        //Element
        if( e.target.files[0] != null ){
            //Element
            this.setState({
                //Element
                N586HZ : e.target.files[0],
                //Element
                Q66D65 : e.target.files[0].name
            })
            //Element
            V8979K.src = URL.createObjectURL( e.target.files[0] )
            //Element
            W371FK.append( "image", e.target.files[0] )
            //Element
            Axios.post( "https://api.renga.com.tr/upload", W371FK )
            //Element
            .then( F7Z9Y5 => {
                //Element
                sessionStorage.removeItem( "Web_Y379P8" )
                //Element
                sessionStorage.setItem( "Web_Y379P8", F7Z9Y5.data )
                //Element
                if( F7Z9Y5.data != null ){
                    //Element
                    if( J015SS != null ){
                        //Element
                        J015SS.disabled  = false
                    }
                    //Element
                    if( JUL570 != null ){
                        //Element
                        JUL570.disabled  = false
                    }
                }
            })
            //Element
            K92132.innerHTML = "Fotoğraf"
        }
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 mb-3 c971b1" },
                //Element
                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                    //Element
                    "Fotoğraf"
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                    //Element
                    "Sadece *.png, *.jpg ve *.jpeg Formatları Kabul Edilir."
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 ix0q18" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 d8z4l3" },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 kl579w" },
                        //Element
                        React.createElement( "div", { className: "float-start w-auto p-0 m-0 p43687" },
                            //Element
                            React.createElement( GF917T, {} )
                        ),
                        //Element
                        React.createElement( "div", { className: "float-start w-auto p-0 m-0 d-none klf458" },
                            //Element
                            React.createElement( "img", { id: "Web_S696YN", alt: "Fotoğraf", title: "Fotoğraf", src: "", width: "200", height: "auto" } )
                        ),
                        //Element
                        React.createElement( "label", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute rounded-circle f5f48j", htmlFor: "Web_N5X6E9" },
                            //Element
                            React.createElement( UTT059, {} ),
                            //Element
                            React.createElement( "input", { id: "Web_N5X6E9", name: "Web_N5X6E9", className: "d-none", type: "file", accept: ".png, .jpg, .jpeg", onChange: this.A503G4 } )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Upload;