//Install
import React, { Component } from "react";
//Install
import { ReactComponent as ROG292 } from "../../Media/Icon/004.svg";

//Element
class Brand extends Component {
    //Element
    Overview = () => {
        //Element
        window.location.href = "/overview"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "button", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 bg-transparent border-0 t7b379", type: "button", onClick: this.Overview },
            //Element
            React.createElement( ROG292, {} )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Brand;