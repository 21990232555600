//Install
import React from "react";
//Install
import Axios from "axios";

//Element
function UQ505Z(e){
    //Element
    e.preventDefault()
    //Element
    let K3OK58, A5517Z, M6TW91, C119AI, J4671Y, Q802NK
    //Element
    K3OK58 = document.getElementById( "Web_L84611" ).value
    //Element
    A5517Z = document.getElementById( "Web_H484N5" ).value
    //Element
    C119AI = document.getElementById( "Web_N5X6E9" ).files.length
    //Element
    J4671Y = document.getElementsByClassName( "p43687" )[0]
    //Element
    Q802NK = document.getElementsByClassName( "uh0823" )[0]
    //Element
    M6TW91 = sessionStorage.getItem( "Web_NZ797P" )
    //Element
    if( C119AI == "0" ){
        //Element
        J4671Y.style.border = "1px solid #1B84FF"
        //Element
        Q802NK.innerHTML    = "Lütfen Fotoğraf Seçin."
        //Element
        J4671Y.focus()
    //Element
    } else {
        //Element
        const Y5381M = async() => {
            //Element
            const Z28H9U = await Axios.get( "https://api.renga.com.tr/timeline/add", {
                //Element
                params : {
                    //Element
                    Title   : K3OK58,
                    //Element
                    Summary : A5517Z,
                    //Element
                    Image   : M6TW91
                }
            })
            //Element
            .catch( J01W1N => {
                //Element
                console.log( J01W1N )
            })
        }
        //Element
        Y5381M()
        //Element
        window.location.href = "/timeline-order"
    }
}
//Element
function New(){
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Tarihçe"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Yeni Ekle"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: UQ505Z },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "ID"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_J1MH81", name: "Web_J1MH81", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: "0", type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Adı"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_L84611", name: "Web_L84611", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Açıklama"
                    ),
                    //Element
                    React.createElement( "textarea", { id: "Web_H484N5", name: "Web_H484N5", className: "float-start w-100 p-3 m-0 mt-2 a49170", required: "required", rows: "5", cols: "20" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                    //Element
                    React.createElement( "button", { id: "Web_V4Z90F", name: "Web_V4Z90F", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                        //Element
                        "Gönder"
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default New;