//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function A8O077(e){
    //Element
    e.preventDefault()
    //Element
    let BO70B1, DL9369, D9590D
    //Element
    BO70B1 = document.getElementById( "Web_DH2997" ).value
    //Element
    DL9369 = document.getElementById( "Web_Q688V1" ).value
    //Element
    D9590D = document.getElementById( "Web_WO702L" ).value
    //Element
    const G7Y82B = async() => {
        //Element
        const IF376B = await Axios.get( "https://api.renga.com.tr/variety/update", {
            //Element
            params : {
                //Element
                ID    : BO70B1,
                //Element
                Title : DL9369,
                //Element
                Date  : D9590D
            }
        })
        //Element
        .catch( M5863U => {
            //Element
            console.log( M5863U )
        })
    }
    //Element
    G7Y82B()
    //Element
    window.location.href = "/variety"
}
//Element
function PO7Y26(e){ 
    //Element
    e.preventDefault()
    //Element
    let J82N5B = document.getElementById( "Web_DH2997" ).value
    //Element
    const GJ804G = async() => {
        //Element
        const P25667 = await Axios.get( "https://api.renga.com.tr/variety/delete", {
            //Element
            params : {
                //Element
                ID : J82N5B
            }
        })
        //Element
        .catch( G4I098 => {
            //Element
            console.log( G4I098 )
        })
    }
    //Element
    GJ804G()
    //Element
    window.location.href = "/variety"
}
//Element
function Edit(){
    //Element
    const { ID } = useParams()
    //Element
    let L97P4V, I8Y323
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const DK705O = async() => {
            //Element
            const FI791G = await Axios.get( "https://api.renga.com.tr/variety/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            } )
            //Element
            C68006( FI791G.data )
        }
        //Element
        DK705O()
    }, [] )
    //Element
    G7TI26.map( (M8GO77) => {
        //Element
        L97P4V = M8GO77.Title
        //Element
        I8Y323 = M8GO77.Date
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Çeşitlilik"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Bilgi Güncelleme"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Kategori ID"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_DH2997", name: "Web_DH2997", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Kategori Adı"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_Q688V1", name: "Web_Q688V1", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: L97P4V, type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Tarih"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_WO702L", name: "Web_WO702L", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: I8Y323, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_F324FY", name: "Web_F324FY", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: PO7Y26 },
                                    //Element
                                    "Sil"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_MU01T4", name: "Web_MU01T4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: A8O077 },
                                    //Element
                                    "Güncelle"
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;