//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import { CKEditor } from "@ckeditor/ckeditor5-react";
//Install
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//Install
import Upload from "../../Shortcode/Product/Image";

//Element
class New extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            P6631T : [],
            //Element
            U13KG9 : [],
            //Element
            HR384Q : [],
            //Element
            QU1204 : [],
            //Element
            F984US : [],
            //Element
            N6I51E : []
        }
    }
    //Element
    I46G4X = () => {
        //Element
        const D0IQ34 = async() => {
            //Element
            const D4H7I7 = await Axios.get( "https://api.renga.com.tr/variety", {} )
            //Element
            this.setState({
                //Element
                P6631T : D4H7I7.data
            })
        }
        //Element
        D0IQ34()
    }
    //Element
    X595YJ = () => {
        //Element
        const C1XE32 = async() => {
            //Element
            const F7754P = await Axios.get( "https://api.renga.com.tr/color", {} )
            //Element
            this.setState({
                //Element
                U13KG9 : F7754P.data
            })
        }
        //Element
        C1XE32()
    }
    //Element
    N0V48A = (e) => {
        //Element
        const X48BR7 = async() => {
            //Element
            Axios.get( "https://api.renga.com.tr/bottle/category", {
                //Element
                params : {
                    //Element
                    ID : e.target.value
                }
            })
            //Element
            .then( D07762 => {
                //Element
                this.setState({
                    //Element
                    N6I51E : D07762.data
                })
                //Element
                if( D07762.statusText == "OK" ){
                    //Element
                    Axios.get( "https://api.renga.com.tr/volume/category", {
                        //Element
                        params : {
                            //Element
                            ID : e.target.value
                        }
                    })
                    //Element
                    .then( D07762 => {
                        //Element
                        this.setState({
                            //Element
                            F984US : D07762.data
                        })
                    })
                }
            })
        }
        //Element
        X48BR7()
    }
    //Element
    TV0805 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let MGV429, O34U7G, R786MQ, LE0628, P1K643, P969X5, C4Y46F, F9O8G5, Y00R73, P2R735, O4CE93, A269C8, T574N5, E4I16W, B89H87, R6A7H6, Q8568D, W0527M, P107VD, B998JM
        //Element
        let INW968, CMR289, G7Y3U9, TT0G95, Y84U17, D04498, C575NQ, K5251P, M317P5
        //Element
        let AS1M96, E85H63, C704DC
        //Element
        let BV255N, Y646CG, J4671Y, Q802NK
        //Element
        O34U7G = document.getElementById( "Web_P65395" ).value
        //Element
        R786MQ = document.getElementById( "Web_Y71949" ).value
        //Element
        B89H87 = document.getElementById( "Web_K4F83W" ).value
        //Element
        LE0628 = document.getElementById( "Web_U45Q45" ).value
        //Element
        P1K643 = document.getElementById( "Web_LK55R0" ).value
        //Element
        P969X5 = document.getElementById( "Web_GV376I" ).value
        //Element
        C4Y46F = document.getElementsByClassName( "ck-editor__editable_inline" )[0].innerHTML
        //Element
        F9O8G5 = document.getElementById( "Web_GO973H" ).value
        //Element
        Y00R73 = document.getElementById( "Web_EW280E" ).value
        //Element
        P2R735 = document.getElementById( "Web_R4399Q" ).value
        //Element
        O4CE93 = document.getElementById( "Web_T134PW" ).value
        //Element
        A269C8 = document.getElementById( "Web_D642MB" ).value
        //Element
        R6A7H6 = document.getElementById( "Web_F3AG13" ).value
        //Element
        Q8568D = document.getElementById( "Web_M073CM" ).value
        //Element
        W0527M = document.getElementById( "Web_VT4J18" ).value
        //Element
        P107VD = document.getElementById( "Web_CP177E" ).value
        //Element
        B998JM = document.getElementById( "Web_E684KO" ).value
        //Element
        AS1M96 = document.getElementById( "Web_J812S4" ).value
        //Element
        E85H63 = document.getElementById( "Web_U422LT" ).value
        //Element
        C704DC = document.getElementById( "Web_XD567D" ).value
        //Element
        T574N5 = sessionStorage.getItem( "Web_N518EM" )
        //Element
        INW968 = sessionStorage.getItem( "Web_L3N3U8" )
        //Element
        CMR289 = sessionStorage.getItem( "Web_N7O7F6" )
        //Element
        G7Y3U9 = sessionStorage.getItem( "Web_Y748IW" )
        //Element
        TT0G95 = sessionStorage.getItem( "Web_K4J317" )
        //Element
        Y84U17 = sessionStorage.getItem( "Web_D3842S" )
        //Element
        D04498 = sessionStorage.getItem( "Web_L1D37N" )
        //Element
        C575NQ = sessionStorage.getItem( "Web_YN2X81" )
        //Element
        K5251P = sessionStorage.getItem( "Web_CXO598" )
        //Element
        M317P5 = sessionStorage.getItem( "Web_U22E35" )
        //Element
        BV255N = document.getElementById( "Web_N5X6E9" ).files.length
        //Element
        Y646CG = document.getElementById( "Web_N87TJ3" ).files.length
        //Element
        J4671Y = document.getElementsByClassName( "p43687" )[0]
        //Element
        Q802NK = document.getElementsByClassName( "uh0823" )[0]
        //Element
        E4I16W = document.getElementById( "Web_W34HX9" ).value
        //Element
        if( BV255N == "0" ){
            //Element
            window.scrollTo({
                //Element
                top      : 0,
                //Element
                behavior : "smooth" 
            })
            //Element
            J4671Y.style.border = "1px solid #1B84FF"
            //Element
            Q802NK.innerHTML    = "Lütfen Fotoğraf Seçin."
        //Element
        } else {
            //Element
            if( Y646CG == "0" ){
                //Element
                document.getElementById( "Web_V32KA9" ).style.border = "1px solid #1B84FF"
                //Element
                document.querySelector( 'label[for="Web_R494VD"]' ).innerHTML = "Lütfen Fotoğraf Seçin."
            //Element
            } else {
                //Element
                const U09H0S = async() => {
                    //Element
                    const K1308D = await Axios.get( "https://api.renga.com.tr/product/add", {
                        //Element
                        params : {
                            //Element
                            Unique_ID : B89H87,
                            //Element
                            Title     : LE0628,
                            //Element
                            SubTitle  : P1K643,
                            //Element
                            Summary   : C4Y46F,
                            //Element
                            Image     : T574N5,
                            //Element
                            Variety   : P969X5,
                            //Element
                            Bottle    : A269C8,
                            //Element
                            Volume    : O4CE93,
                            //Element
                            Date      : O34U7G,
                            //Element
                            Author    : R786MQ,
                            //Element
                            iFrame    : E4I16W,
                            //Element
                            Cubic     : R6A7H6,
                            //Element
                            Weight    : Q8568D,
                            //Element
                            Piece     : W0527M,
                            //Element
                            Packing   : P107VD,
                            //Element
                            Barcode   : B998JM,
                            //Element
                            Tag_One   : AS1M96,
                            //Element
                            Tag_Two   : E85H63,
                            //Element
                            Tag_Three : C704DC
                        }
                    })
                    //Element
                    .then( I2L9R9 => {
                        //Element
                        MGV429 = I2L9R9.data.insertId
                        //Element
                        if( I2L9R9.statusText == "OK" ){
                            //Element
                            Axios.get( "https://api.renga.com.tr/product/color/add", {
                                //Element
                                params : {
                                    //Element
                                    ID     : MGV429,
                                    //Element
                                    First  : F9O8G5,
                                    //Element
                                    Second : Y00R73,
                                    //Element
                                    Third  : P2R735
                                }
                            })
                            //Element
                            .then( N5979P => {
                                //Element
                                if( N5979P.statusText == "OK" ){
                                    //Element
                                    Axios.get( "https://api.renga.com.tr/product/image/add", {
                                        //Element
                                        params : {
                                            //Element
                                            ID      : MGV429,
                                            //Element
                                            First   : INW968,
                                            //Element
                                            Second  : CMR289,
                                            //Element
                                            Third   : G7Y3U9,
                                            //Element
                                            Fourth  : TT0G95,
                                            //Element
                                            Fifth   : Y84U17,
                                            //Element
                                            Sixth   : D04498,
                                            //Element
                                            Seventh : C575NQ,
                                            //Element
                                            Eighth  : K5251P,
                                            //Element
                                            Eighth  : K5251P,
                                            //Element
                                            Ninth   : M317P5
                                        }
                                    })
                                    //Element
                                    .then( I8P7J8 => {
                                        //Element
                                        if( I8P7J8.statusText == "OK" ){
                                            //Element
                                            sessionStorage.removeItem( "Web_N518EM" )
                                            //Element
                                            sessionStorage.removeItem( "Web_L3N3U8" )
                                            //Element
                                            sessionStorage.removeItem( "Web_N7O7F6" )
                                            //Element
                                            sessionStorage.removeItem( "Web_Y748IW" )
                                            //Element
                                            sessionStorage.removeItem( "Web_K4J317" )
                                            //Element
                                            sessionStorage.removeItem( "Web_D3842S" )
                                            //Element
                                            sessionStorage.removeItem( "Web_L1D37N" )
                                            //Element
                                            sessionStorage.removeItem( "Web_YN2X81" )
                                            //Element
                                            sessionStorage.removeItem( "Web_CXO598" )
                                            //Element
                                            sessionStorage.removeItem( "Web_U22E35" )
                                            //Element
                                            window.location.href = "/product-order"
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
                //Element
                U09H0S()
            }
        }
    }
    //Element
    componentDidMount(){
        //Element
        this.I46G4X()
        //Element
        this.X595YJ()
    }
    //Element
    render() {
        //Element
        let E6J29T = new Date()
        //Element
        let K0JH42 = E6J29T.toLocaleDateString( "tr-TR" )
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
                //Element
                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                    //Element
                    "Ürünler"
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                    //Element
                    "Yeni Ekle"
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
                //Element
                React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: this.TV0805 },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "ID"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_V180MF", name: "Web_V180MF", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: "0", type: "text", required: "required", disabled: "disabled" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Tarih"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_P65395", name: "Web_P65395", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", defaultValue: K0JH42, required: "required", disabled: "disabled" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Admin"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_Y71949", name: "Web_Y71949", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", defaultValue: "Renga.com.tr", required: "required", disabled: "disabled" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Unique ID"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_K4F83W", name: "Web_K4F83W", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Ürün Başlığı"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_U45Q45", name: "Web_U45Q45", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Alt Başlığı"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_LK55R0", name: "Web_LK55R0", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Kategori"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_GV376I", name: "Web_GV376I", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq", onChange: this.N0V48A },
                                    //Element
                                    React.createElement( "option", { value: "0" },
                                        //Element
                                        "Kategori Seçin"
                                    ),
                                    //Element
                                    this.state.P6631T.map( (H14754) => {
                                        //Element
                                        return React.createElement( "option", { key: H14754.ID, value: H14754.ID },
                                            //Element
                                            H14754.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Matara"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_D642MB", name: "Web_D642MB", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                    //Element
                                    React.createElement( "option", { value: "0" },
                                        //Element
                                        "Matara Seçin"
                                    ),
                                    //Element
                                    this.state.N6I51E.map( (B493EH) => {
                                        //Element
                                        return React.createElement( "option", { key: B493EH.ID, value: B493EH.ID },
                                            //Element
                                            B493EH.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Hacim"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_T134PW", name: "Web_T134PW", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                    //Element
                                    React.createElement( "option", { value: "0" },
                                        //Element
                                        "Hacim Seçin"
                                    ),
                                    //Element
                                    this.state.F984US.map( (C8L99Z) => {
                                        //Element
                                        return React.createElement( "option", { key: C8L99Z.ID, value: C8L99Z.ID },
                                            //Element
                                            C8L99Z.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Hacim m³"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_F3AG13", name: "Web_F3AG13", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Ağırlığı"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_M073CM", name: "Web_M073CM", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Adet"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_VT4J18", name: "Web_VT4J18", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Ambalaj"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_CP177E", name: "Web_CP177E", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Barkod"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_E684KO", name: "Web_E684KO", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 c21d8j" },
                                    //Element
                                    "Video"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_W34HX9", name: "Web_W34HX9", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Etiket"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_J812S4", name: "Web_J812S4", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Etiket"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_U422LT", name: "Web_U422LT", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Etiket"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_XD567D", name: "Web_XD567D", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 c21d8j" },
                                    //Element
                                    "Açıklama"
                                ),
                                //Element
                                React.createElement( CKEditor, { editor: ClassicEditor } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Renk"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_GO973H", name: "Web_GO973H", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                    //Element
                                    React.createElement( "option", { value: "0" },
                                        //Element
                                        "Lütfen Seçim Yapın"
                                    ),
                                    //Element
                                    this.state.U13KG9.map( (H3419W) => {
                                        //Element
                                        return React.createElement( "option", { key: H3419W.ID, value: H3419W.ID },
                                            //Element
                                            H3419W.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 c21d8j" },
                                    //Element
                                    "Renk"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_EW280E", name: "Web_EW280E", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                    //Element
                                    React.createElement( "option", { value: "0" },
                                        //Element
                                        "Lütfen Seçim Yapın"
                                    ),
                                    //Element
                                    this.state.U13KG9.map( (H3419W) => {
                                        //Element
                                        return React.createElement( "option", { key: H3419W.ID, value: H3419W.ID },
                                            //Element
                                            H3419W.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 c21d8j" },
                                    //Element
                                    "Renk"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_R4399Q", name: "Web_R4399Q", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                    //Element
                                    React.createElement( "option", { value: "0" },
                                        //Element
                                        "Lütfen Seçim Yapın"
                                    ),
                                    //Element
                                    this.state.U13KG9.map( (H3419W) => {
                                        //Element
                                        return React.createElement( "option", { key: H3419W.ID, value: H3419W.ID },
                                            //Element
                                            H3419W.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Row, {},
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_R494VD" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_R494VD", For: "Web_N87TJ3", SVG: "Web_V32KA9", Image: "Web_V5K852" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_Y123I3" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_Y123I3", For: "Web_Y8BN79", SVG: "Web_QR150K", Image: "Web_XG45T3" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_W5F6C8" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_W5F6C8", For: "Web_E2F4K5", SVG: "Web_Q0V66Y", Image: "Web_Y1V817" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_Z869M3" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_Z869M3", For: "Web_AE39H6", SVG: "Web_I082EZ", Image: "Web_C14B2O" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_J558OJ" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_J558OJ", For: "Web_F97XR5", SVG: "Web_I473TL", Image: "Web_W7IX78" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_Q071RS" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_Q071RS", For: "Web_PF0S40", SVG: "Web_Z0C098", Image: "Web_C08O21" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_V343CB" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_V343CB", For: "Web_D445HD", SVG: "Web_R453OQ", Image: "Web_OJ881E" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_B285F7" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_B285F7", For: "Web_Q47S38", SVG: "Web_N0434L", Image: "Web_J72SK4" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_T066I5" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_T066I5", For: "Web_T4JU45", SVG: "Web_T7F39L", Image: "Web_O80M2K" } )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "button", { id: "Web_D845DF", name: "Web_D845DF", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                                    //Element
                                    "Gönder"
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default New;