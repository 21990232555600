//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function C265X5(e){ 
    //Element
    e.preventDefault()
    //Element
    let J2I87A, Q140V6, Z87U00, YJ8701
    //Element
    J2I87A = document.getElementById( "Web_WJ611K" ).value
    //Element
    Q140V6 = document.getElementById( "Web_F2K32H" ).value
    //Element
    Z87U00 = document.getElementById( "Web_N3Q236" ).value
    //Element
    YJ8701 = document.getElementById( "Web_QX7787" ).value
    //Element
    const U236HS = async() => {
        //Element
        const M36835 = await Axios.get( "https://api.renga.com.tr/volume/update", {
            //Element
            params : {
                //Element
                ID       : J2I87A,
                //Element
                Title    : Q140V6,
                //Element
                Category : Z87U00,
                //Element
                Date      : YJ8701
            }
        })
        //Element
        .catch( E0704U => {
            //Element
            console.log( E0704U )
        })
    }
    //Element
    U236HS()
    //Element
    window.location.href = "/volume"
}
//Element
function F157UE(e){ 
    //Element
    e.preventDefault()
    //Element
    let C3P17E = document.getElementById( "Web_WJ611K" ).value
    //Element
    const EF9210 = async() => {
        //Element
        const VT72K1 = await Axios.get( "https://api.renga.com.tr/volume/delete", {
            //Element
            params : {
                //Element
                ID : C3P17E
            }
        })
        //Element
        .catch( FF285O => {
            //Element
            console.log( FF285O )
        })
    }
    //Element
    EF9210()
    //Element
    window.location.href = "/volume"
}
//Element
function Edit(){
    //Element
    let J914KW = new Date()
    //Element
    let K7041Y = J914KW.toLocaleDateString( "tr-TR" )
    //Element
    const { ID } = useParams()
    //Element
    let T01M08
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    const [ D79551, IK946T ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const PV4154 = async() => {
            //Element
            const D33299 = await Axios.get( "https://api.renga.com.tr/volume/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            } )
            //Element
            C68006( D33299.data )
        }
        //Element
        PV4154()
        //Element
        const M10268 = async() => {
            //Element
            const NM9557 = await Axios.get( "https://api.renga.com.tr/variety", {} )
            //Element
            IK946T( NM9557.data )
        }
        //Element
        M10268()
    }, [] )
    //Element
    G7TI26.map( (M64V1H) => {
        //Element
        T01M08 = M64V1H.Title
        //Element
        document.getElementById( "Web_N3Q236" ).value = M64V1H.Category
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Hacim"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Bilgi Güncelleme"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Hacim ID"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_WJ611K", name: "Web_WJ611K", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Hacim Adı"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_F2K32H", name: "Web_F2K32H", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: T01M08, type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Kategori"
                    ),
                    //Element
                    React.createElement( "select", { id: "Web_N3Q236", name: "Web_N3Q236", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                        //Element
                        D79551.map( (H3419W) => {
                            //Element
                            return React.createElement( "option", { key: H3419W.ID, value: H3419W.ID },
                                //Element
                                H3419W.Title
                            )
                        })
                    )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Tarih"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_QX7787", name: "Web_QX7787", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: K7041Y, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_IF6E80", name: "Web_IF6E80", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: F157UE },
                                    //Element
                                    "Sil"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: C265X5 },
                                    //Element
                                    "Güncelle"
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;