//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as W6L568 } from "../../Media/Icon/011.svg";
//Install
import propTypes from "prop-types";

//Element
class Blog extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Time, Image, Target } = this.props
        //Element
        let C9189D = "https://api.renga.com.tr/images/"
        //Element
        let T5F58P = "/blog-edit/"
        //Element
        let N4T475 = C9189D + Image
        //Element
        let Q7134W = T5F58P + ID
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 mt-3 eoj420" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:2 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 xln744" },
                        //Element
                        React.createElement( "img", { alt: Title, title: Title, src: N4T475, width: "72", height: "auto" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:2 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 j9i516" },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 text-truncate lh-1 z9815k" },
                            //Element
                            ID
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:4 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 j9i516" },
                        //Element
                        React.createElement( "h4", { className: "float-start w-auto p-0 m-0 text-truncate lh-1 c432qc" },
                            //Element
                            Title
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:2 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 j9i516" },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 text-truncate lh-1 z9815k" },
                            //Element
                            Time
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:2 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 j9i516" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 p6172g", href: Q7134W },
                            //Element
                            React.createElement( W6L568, {} )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Blog.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Time   : propTypes.string.isRequired,
    Image  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Blog;