//Install
import React, { useState, useEffect } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Header from "../../Header";
//Install
import Footer from "../../Footer";

//Element
function A5L82C(){
    //Element
    document.title = "Merhaba : Renga - Renga.com.tr"
}
//Element
function VQ4A17(e){
    //Element
    e.preventDefault()
    //Element
    let F19AP4, F04780, G66GH0, ZB381R, F44M37, B790KU, QFX893
    //Element
    F19AP4 = document.getElementById( "Web_C51G2W" ).value
    //Element
    F04780 = document.getElementById( "Web_E499FN" ).value
    //Element
    G66GH0 = document.getElementById( "Web_MI2441" ).value
    //Element
    ZB381R = document.getElementById( "Web_U8L57P" ).value
    //Element
    F44M37 = document.getElementById( "Web_P67G7H" ).value
    //Element
    B790KU = document.getElementById( "Web_K640L4" ).value
    //Element
    QFX893 = document.getElementById( "Web_Q48R4Y" ).value
    //Element
    const B2R3P1 = async() => {
        //Element
        const L0EH50 = await Axios.get( "https://api.renga.com.tr/settings/update", {
            //Element
            params : {
                //Element
                Address            : F19AP4,
                //Element
                Telephone          : F04780,
                //Element
                Fax                : G66GH0,
                //Element
                Email              : ZB381R,
                //Element
                Showroom           : F44M37,
                //Element
                Showroom_Telephone : B790KU,
                //Element
                Showroom_Email     : QFX893
            }
        })
        //Element
        .catch( Q10O40 => {
            //Element
            console.log( Q10O40 )
        })
    }
    //Element
    B2R3P1()
    //Element
    window.location.href = "/setting"
}
//Element
function Default(){
    //Element
    A5L82C()
    //Element
    let YS014L = [ "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık" ]
    //Element
    let E34245 = new Date()
    //Element
    let E89J9A = E34245.getDate()
    //Element
    let L0047O = YS014L[ E34245.getMonth() ]
    //Element
    let H8E93O = E34245.getFullYear()
    //Element
    let R991JQ = E34245.getHours()
    //Element
    let A4621K = E34245.getMinutes()
    //Element
    let B348QM = ":"
    //Element
    let P10047 = " "
    //Element
    let D767O5 = E89J9A + P10047 + L0047O + P10047 + H8E93O + P10047 + R991JQ + B348QM + A4621K
    //Element
    let I9C184, S5T749, YW186A, N51S5N, L5G50Y, E0H803, I4Y8V4, HRL496
    //Element
    const [ SW537H, X52265 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const B8WJ93 = async() => {
            //Element
            const V4679X = await Axios.get( "https://api.renga.com.tr/settings", {} )
            //Element
            X52265( V4679X.data )
        }
        //Element
        B8WJ93()
    }, [] )
    //Element
    SW537H.map( (M634B4) => {
        //Element
        I9C184 = M634B4.ID
        //Element
        S5T749 = M634B4.Address
        //Element
        YW186A = M634B4.Telephone
        //Element
        N51S5N = M634B4.Fax
        //Element
        L5G50Y = M634B4.Email
        //Element
        E0H803 = M634B4.Showroom
        //Element
        I4Y8V4 = M634B4.Showroom_Telephone
        //Element
        HRL496 = M634B4.Showroom_Email
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v9339k" },
        //Element
        React.createElement( Header, { ID: "0", Title: "Merhaba", Status: D767O5 } ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ga8x38" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                                    //Element
                                    "Şirket Bilgileri"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                                    //Element
                                    "Bilgi Düzenleme"
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
                                //Element
                                React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: VQ4A17 },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "ID"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_M1M849", name: "Web_M1M849", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: I9C184, type: "text", required: "required", disabled: "disabled" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Merkez Ofis Adres"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_C51G2W", name: "Web_C51G2W", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: S5T749, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Telefon"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_E499FN", name: "Web_E499FN", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: YW186A, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "E-mail"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_U8L57P", name: "Web_U8L57P", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: L5G50Y, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Fax"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_MI2441", name: "Web_MI2441", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: N51S5N, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "İstoç Renga Showroom Adres"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_P67G7H", name: "Web_P67G7H", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: E0H803, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Telefon"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_K640L4", name: "Web_K640L4", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: I4Y8V4, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "E-mail"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_Q48R4Y", name: "Web_Q48R4Y", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: HRL496, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "button", { id: "Web_MM727B", name: "Web_MM727B", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                                                    //Element
                                                    "Gönder"
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( Footer, {} )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Default;