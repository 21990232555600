//Install
import React from "react";
//Install
import Axios from "axios";

//Element
function V6G09Z(e){
    //Element
    e.preventDefault()
    //Element
    let Y455QI, G669GT, F2038Z, CE026S, J4671Y, Q802NK
    //Element
    Y455QI = document.getElementById( "Web_L84611" ).value
    //Element
    G669GT = document.getElementById( "Web_BA3L06" ).value
    //Element
    CE026S = document.getElementById( "Web_N5X6E9" ).files.length
    //Element
    J4671Y = document.getElementsByClassName( "p43687" )[0]
    //Element
    Q802NK = document.getElementsByClassName( "uh0823" )[0]
    //Element
    F2038Z = sessionStorage.getItem( "Web_Y379P8" )
    //Element
    if( CE026S == "0" ){
        //Element
        J4671Y.style.border = "1px solid #1B84FF"
        //Element
        Q802NK.innerHTML    = "Lütfen Fotoğraf Seçin."
        //Element
        J4671Y.focus()
    //Element
    } else {
        //Element
        J4671Y.style.border = "1px solid #FFFFFF"
        //Element
        const YZ475P = async() => {
            //Element
            const Z50B9L = await Axios.get( "https://api.renga.com.tr/slider/add", {
                //Element
                params : {
                    //Element
                    Title   : Y455QI,
                    //Element
                    Image   : F2038Z,
                    //Element
                    Target  : G669GT
                }
            })
            //Element
            .catch( H5L81E => {
                //Element
                console.log( H5L81E )
            })
        }
        //Element
        YZ475P()
        //Element
        window.location.href = "/slider-order"
    }
}
//Element
function New(){
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Slider"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Yeni Ekle"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: V6G09Z },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "ID"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_J1MH81", name: "Web_J1MH81", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: "0", type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Adı"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_L84611", name: "Web_L84611", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Ürün"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_BA3L06", name: "Web_BA3L06", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                    //Element
                    React.createElement( "button", { id: "Web_V4Z90F", name: "Web_V4Z90F", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                        //Element
                        "Gönder"
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default New;