//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function X3G281(e){ 
    //Element
    e.preventDefault()
    //Element
    let Q5M41R, B4AB51, A7P003, U03225
    //Element
    Q5M41R = document.getElementById( "Web_HZ04O4" ).value
    //Element
    B4AB51 = document.getElementById( "Web_WY1M47" ).value
    //Element
    A7P003 = document.getElementById( "Web_T4311E" ).value
    //Element
    U03225 = sessionStorage.getItem( "Web_N748Z0" )
    //Element
    const HI4U86 = async() => {
        //Element
        const IG034X = await Axios.get( "https://api.renga.com.tr/document/update", {
            //Element
            params : {
                //Element
                ID      : Q5M41R,
                //Element
                Title   : B4AB51,
                //Element
                Summary : A7P003,
                //Element
                Image   : U03225
            }
        })
        //Element
        .catch( K2V426 => {
            //Element
            console.log( K2V426 )
        })
    }
    //Element
    HI4U86()
    //Element
    window.location.href = "/document-order"
}
//Element
function A073SV(e){ 
    //Element
    e.preventDefault()
    //Element
    let ML126K
    //Element
    ML126K = document.getElementById( "Web_HZ04O4" ).value
    //Element
    const L1Z1Z5 = async() => {
        //Element
        const D208WI = await Axios.get( "https://api.renga.com.tr/document/delete", {
            //Element
            params : {
                //Element
                ID : ML126K
            }
        })
        //Element
        .catch( W907BL => {
            //Element
            console.log( W907BL )
        })
    }
    //Element
    L1Z1Z5()
    //Element
    window.location.href = "/document-order"
}
//Element
function Edit(){
    //Element
    let E445J6   = "https://api.renga.com.tr/images/"
    //Element
    const { ID } = useParams()
    //Element
    let I602XD, S8TT28, L9619R
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const D172J2 = async() => {
            //Element
            const V1WI25 = await Axios.get( "https://api.renga.com.tr/document/select", {
                //Element
                params : {
                    //Element
                    ID      : ID,
                    //Element
                    Title   : I602XD,
                    //Element
                    Summary : S8TT28
                }
            } )
            //Element
            C68006( V1WI25.data )
        }
        //Element
        D172J2()
    }, [] )
    //Element
    G7TI26.map( (Data) => {
        //Element
        I602XD = Data.Title
        //Element
        S8TT28 = Data.Summary
        //Element
        L9619R = Data.Image
    })
    //Element
    useEffect( () => {
        //Element
        let O544PR   = E445J6 + L9619R
        //Element
        const CK77C1 = document.querySelector( "#Web_S3D27C" )
        //Element
        CK77C1.src   = O544PR
        //Element
        document.getElementsByClassName( "klf458" )[0].classList.remove( "d-none" )
        //Element
        document.getElementsByClassName( "p43687" )[0].classList.add( "d-none" )
    })
    //Element
    sessionStorage.setItem( "Web_N748Z0", L9619R )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Belge"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Bilgi Düzenleme"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "ID"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_HZ04O4", name: "Web_HZ04O4", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Ödül Başlığı"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_WY1M47", name: "Web_WY1M47", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: I602XD, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Bilgilendirme"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_T4311E", name: "Web_T4311E", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                    //Element
                                    React.createElement( "button", { id: "Web_IF6E80", name: "Web_IF6E80", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: A073SV },
                                        //Element
                                        "Sil"
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                    //Element
                                    React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: X3G281 },
                                        //Element
                                        "Güncelle"
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;