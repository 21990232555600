//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import { CKEditor } from "@ckeditor/ckeditor5-react";
//Install
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//Install
import Upload from "../../Shortcode/Product/Image";

//Element
function E641ZE(e){ 
    //Element
    e.preventDefault()
    //Element
    let W834TC, P9T783, WU5545, X969ZO, N8V61O, LA6313, C4Y46F
    //Element
    let INW968, CMR289, G7Y3U9
    //Element
    W834TC = document.getElementById( "Web_HZ04O4" ).value
    //Element
    P9T783 = document.getElementById( "Web_WY1M47" ).value
    //Element
    WU5545 = document.getElementById( "Web_P9340F" ).value
    //Element
    X969ZO = document.getElementById( "Web_BZ457J" ).value
    //Element
    N8V61O = document.getElementById( "Web_P49O4A" ).value
    //Element
    C4Y46F = document.getElementsByClassName( "ck-editor__editable_inline" )[0].innerHTML
    //Element
    LA6313 = sessionStorage.getItem( "Web_JD126T" )
    //Element
    INW968 = sessionStorage.getItem( "Web_L3N3U8" )
    //Element
    CMR289 = sessionStorage.getItem( "Web_N7O7F6" )
    //Element
    G7Y3U9 = sessionStorage.getItem( "Web_Y748IW" )
    //Element
    const U2685C = async() => {
        //Element
        const ON533I = await Axios.get( "https://api.renga.com.tr/prize/update", {
            //Element
            params : {
                //Element
                ID      : W834TC,
                //Element
                Title   : P9T783,
                //Element
                One     : WU5545,
                //Element
                Two     : X969ZO,
                //Element
                Three   : N8V61O,
                //Element
                Image   : LA6313,
                //Element
                Summary : C4Y46F,
                //Element
                First   : INW968,
                //Element
                Second  : CMR289,
                //Element
                Third   : G7Y3U9
            }
        })
        //Element
        .catch( F5RH50 => {
            //Element
            console.log( F5RH50 )
        })
    }
    //Element
    U2685C()
    //Element
    window.location.href = "/prize-order"
}
//Element
function V5Q309(e){ 
    //Element
    e.preventDefault()
    //Element
    let V875LI = document.getElementById( "Web_HZ04O4" ).value
    //Element
    const DU492T = async() => {
        //Element
        const B900Z6 = await Axios.get( "https://api.renga.com.tr/prize/delete", {
            //Element
            params : {
                //Element
                ID : V875LI
            }
        })
        //Element
        .catch( Y3X600 => {
            //Element
            console.log( Y3X600 )
        })
    }
    //Element
    DU492T()
    //Element
    window.location.href = "/prize-order"
}
//Element
function Edit(){
    //Element
    let R0A49E = "https://api.renga.com.tr/images/"
    //Element
    const { ID } = useParams()
    //Element
    let W834TC, P9T783, WU5545, X969ZO, N8V61O, X4D56S
    //Element
    let INW968, CMR289, G7Y3U9
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const N5666B = async() => {
            //Element
            const R829YR = await Axios.get( "https://api.renga.com.tr/prize/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            } )
            //Element
            C68006( R829YR.data )
        }
        //Element
        N5666B()
    }, [] )
    //Element
    G7TI26.map( (CIB588) => {
        //Element
        W834TC = CIB588.Title
        //Element
        P9T783 = CIB588.One
        //Element
        WU5545 = CIB588.Two
        //Element
        X969ZO = CIB588.Three
        //Element
        N8V61O = CIB588.Image
        //Element
        X4D56S = CIB588.Summary
        //Element
        INW968 = CIB588.First
        //Element
        CMR289 = CIB588.Second
        //Element
        G7Y3U9 = CIB588.Third
        //Element
        sessionStorage.setItem( "Web_L3N3U8", CIB588.First )
        //Element
        sessionStorage.setItem( "Web_N7O7F6", CIB588.Second )
        //Element
        sessionStorage.setItem( "Web_Y748IW", CIB588.Third )
    })
    //Element
    useEffect( () => {
        //Element
        let U3247E   = R0A49E + N8V61O
        //Element
        const K1K84V = document.querySelector( "#Web_G13R7P" )
        //Element
        K1K84V.src   = U3247E
        //Element
        document.getElementsByClassName( "klf458" )[0].classList.remove( "d-none" )
        //Element
        document.getElementsByClassName( "p43687" )[0].classList.add( "d-none" )
    })
    //Element
    sessionStorage.setItem( "Web_JD126T", N8V61O )
    //Element
    let L6Z561 = "https://api.renga.com.tr/images/"
    //Element
    let Y81986 = ""
    //Element
    let O892H9 = ""
    //Element
    const PM942R = document.getElementById( "Web_R494VD" )
    //Element
    const XO6D66 = document.getElementById( "Web_Y123I3" )
    //Element
    const W1SX87 = document.getElementById( "Web_W5F6C8" )
    //Element
    if( INW968 != null && INW968 != "null" ){
        //Element
        document.getElementById( "Web_V32KA9" ).classList.add( "d-none" )
        //Element
        document.getElementById( "Web_V5K852" ).classList.remove( "d-none" )
        //Element
        Y81986     = INW968
        //Element
        O892H9     = L6Z561 + Y81986
        //Element
        PM942R.src = O892H9
        //Element
        if( sessionStorage.getItem( "Web_L3N3U8" ) ){
            //Element
            sessionStorage.removeItem( "Web_L3N3U8" )
            //Element
            sessionStorage.setItem( "Web_L3N3U8", INW968 )
        //Element
        } else {
            //Element
            sessionStorage.setItem( "Web_L3N3U8", INW968 )
        }
    }
    //Element
    if( CMR289 != null && CMR289 != "null" ){
        //Element
        document.getElementById( "Web_QR150K" ).classList.add( "d-none" )
        //Element
        document.getElementById( "Web_XG45T3" ).classList.remove( "d-none" )
        //Element
        Y81986      = CMR289
        //Element
        O892H9      = L6Z561 + Y81986
        //Element
        XO6D66.src  = L6Z561 + Y81986
        //Element
        if( sessionStorage.getItem( "Web_N7O7F6" ) ){
            //Element
            sessionStorage.removeItem( "Web_N7O7F6" )
            //Element
            sessionStorage.setItem( "Web_N7O7F6", CMR289 )
        //Element
        } else {
            //Element
            sessionStorage.setItem( "Web_N7O7F6", CMR289 )
        }
    }
    //Element
    if( G7Y3U9 != null && G7Y3U9 != "null" ){
        //Element
        document.getElementById( "Web_Q0V66Y" ).classList.add( "d-none" )
        //Element
        document.getElementById( "Web_Y1V817" ).classList.remove( "d-none" )
        //Element
        Y81986      = G7Y3U9
        //Element
        O892H9      = L6Z561 + Y81986
        //Element
        W1SX87.src  = L6Z561 + Y81986
        //Element
        if( sessionStorage.getItem( "Web_Y748IW" ) ){
            //Element
            sessionStorage.removeItem( "Web_Y748IW" )
            //Element
            sessionStorage.setItem( "Web_Y748IW", G7Y3U9 )
        //Element
        } else {
            //Element
            sessionStorage.setItem( "Web_Y748IW", G7Y3U9 )
        }
    }
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Ödül"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Bilgi Güncelleme"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "ID"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_HZ04O4", name: "Web_HZ04O4", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Ödül Başlığı"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_WY1M47", name: "Web_WY1M47", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: W834TC, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                        //Element
                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                            //Element
                            "Açıklama"
                        ),
                        //Element
                        React.createElement( CKEditor, { editor: ClassicEditor, data: X4D56S } )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "#1"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_P9340F", name: "Web_P9340F", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: P9T783, type: "text" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "#2"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_BZ457J", name: "Web_BZ457J", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: WU5545, type: "text" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "#3"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_P49O4A", name: "Web_P49O4A", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: X969ZO, type: "text" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:4 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_R494VD" },
                                        //Element
                                        "Fotoğraf"
                                    ),
                                    //Element
                                    React.createElement( Upload, { ID: "Web_R494VD", For: "Web_N87TJ3", SVG: "Web_V32KA9", Image: "Web_V5K852" } )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:4 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_Y123I3" },
                                        //Element
                                        "Fotoğraf"
                                    ),
                                    //Element
                                    React.createElement( Upload, { ID: "Web_Y123I3", For: "Web_Y8BN79", SVG: "Web_QR150K", Image: "Web_XG45T3" } )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:4 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_W5F6C8" },
                                        //Element
                                        "Fotoğraf"
                                    ),
                                    //Element
                                    React.createElement( Upload, { ID: "Web_W5F6C8", For: "Web_E2F4K5", SVG: "Web_Q0V66Y", Image: "Web_Y1V817" } )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                    //Element
                                    React.createElement( "button", { id: "Web_IF6E80", name: "Web_IF6E80", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: V5Q309 },
                                        //Element
                                        "Sil"
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                    //Element
                                    React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: E641ZE },
                                        //Element
                                        "Güncelle"
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;