//Install
import React from "react";
//Install
import Axios from "axios";

//Element
function X9C838(e){
    //Element
    e.preventDefault()
    //Element
    let R0T91R, A4P3Z0
    //Element
    R0T91R = document.getElementById( "Web_EA3B39" ).value
    //Element
    A4P3Z0 = document.getElementById( "Web_U699OW" ).value
    //Element
    const M22761 = async() => {
        //Element
        const SP8325 = await Axios.get( "https://api.renga.com.tr/faq/add", {
            //Element
            params : {
                //Element
                Question : R0T91R,
                //Element
                Reply    : A4P3Z0
            }
        })
        //Element
        .catch( VA6045 => {
            //Element
            console.log( VA6045 )
        })
    }
    //Element
    M22761()
    //Element
    window.location.href = "/faq"
}
//Element
function New(){
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Sıkça Sorulan Sorular"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Yeni Ekle"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: X9C838 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "ID"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_K0664G", name: "Web_K0664G", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", placeholder: "0", type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Soru"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_EA3B39", name: "Web_EA3B39", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Cevap"
                    ),
                    //Element
                    React.createElement( "textarea", { id: "Web_U699OW", name: "Web_U699OW", className: "float-start w-100 p-0 ps-3 pe-3 pt-3 pb-3 m-0 mt-2 k1s43b", rows: "5", cols: "20", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                    //Element
                    React.createElement( "button", { id: "Web_E22T3S", name: "Web_E22T3S", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                        //Element
                        "Gönder"
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default New;