//Install
import React, { useState, useEffect } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import { CKEditor } from "@ckeditor/ckeditor5-react";
//Install
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//Install
import Header from "../../Header";
//Install
import Footer from "../../Footer";

//Element
function A5L82C(){
    //Element
    document.title = "Merhaba : Renga - Renga.com.tr"
}
//Element
function IZ6H91(e){
    //Element
    e.preventDefault()
    //Element
    let SH8707, M2PT56
    //Element
    SH8707 = document.getElementById( "Web_I3694B" ).value
    //Element
    M2PT56 = document.getElementsByClassName( "ck-editor__editable_inline" )[0].innerHTML
    //Element
    const P1TQ24 = async() => {
        //Element
        const MB684D = await Axios.get( "https://api.renga.com.tr/policy/update", {
            //Element
            params : {
                //Element
                Title   : SH8707,
                //Element
                Summary : M2PT56
            }
        })
        //Element
        .catch( U10JN3 => {
            //Element
            console.log( U10JN3 )
        })
    }
    //Element
    P1TQ24()
    //Element
    window.location.href = "/policy"
}
//Element
function Default(){
    //Element
    A5L82C()
    //Element
    let PP032V = new Date()
    //Element
    let A7111U = PP032V.toLocaleDateString( "tr-TR" )
    //Element
    let YS014L = [ "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık" ]
    //Element
    let E34245 = new Date()
    //Element
    let E89J9A = E34245.getDate()
    //Element
    let L0047O = YS014L[ E34245.getMonth() ]
    //Element
    let H8E93O = E34245.getFullYear()
    //Element
    let R991JQ = E34245.getHours()
    //Element
    let A4621K = E34245.getMinutes()
    //Element
    let B348QM = ":"
    //Element
    let P10047 = " "
    //Element
    let D767O5 = E89J9A + P10047 + L0047O + P10047 + H8E93O + P10047 + R991JQ + B348QM + A4621K
    //Element
    let D1M1Y3, BN511E, Q1ZB19
    //Element
    const [ XN336I, F757J5 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const SM3438 = async() => {
            //Element
            const E0YX84 = await Axios.get( "https://api.renga.com.tr/policy", {} )
            //Element
            F757J5( E0YX84.data )
        }
        //Element
        SM3438()
    }, [] )
    //Element
    XN336I.map( (XT9106) => {
        //Element
        D1M1Y3 = XT9106.ID
        //Element
        BN511E = XT9106.Title
        //Element
        Q1ZB19 = XT9106.Summary
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v9339k" },
        //Element
        React.createElement( Header, { ID: "0", Title: "Merhaba", Status: D767O5 } ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ga8x38" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                                    //Element
                                    "Kurumsal"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                                    //Element
                                    "Bilgi Güncelleme"
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
                                //Element
                                React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: IZ6H91 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "ID"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_R705TJ", name: "Web_R705TJ", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: D1M1Y3, type: "text", required: "required", disabled: "disabled" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "Ad"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_I3694B", name: "Web_I3694B", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: BN511E, type: "text", required: "required" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "Açıklama"
                                        ),
                                        //Element
                                        React.createElement( CKEditor, { editor: ClassicEditor, data: Q1ZB19 } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "Tarih"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_J307PS", name: "Web_J307PS", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", placeholder: A7111U, type: "text", required: "required", disabled: "disabled" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "button", { id: "Web_OH5435", name: "Web_OH5435", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                                            //Element
                                            "Gönder"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( Footer, {} )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Default;