//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function L9627W(e){ 
    //Element
    e.preventDefault()
    //Element
    let L194MO, W96526, A6951A, Z769L6
    //Element
    L194MO = document.getElementById( "Web_F87699" ).value
    //Element
    A6951A = document.getElementById( "Web_C157RB" ).value
    //Element
    W96526 = document.getElementById( "Web_BA3L06" ).value
    //Element
    Z769L6 = sessionStorage.getItem( "Web_Y379P8" )
    //Element
    const C556N7 = async() => {
        //Element
        const GF489R = await Axios.get( "https://api.renga.com.tr/slider/update", {
            //Element
            params : {
                //Element
                ID      : L194MO,
                //Element
                Title   : A6951A,
                //Element
                Image   : Z769L6,
                //Element
                Target  : W96526
            }
        })
        //Element
        .catch( C2T77Q => {
            //Element
            console.log( C2T77Q )
        })
    }
    //Element
    C556N7()
    //Element
    window.location.href = "/slider-order"
}
//Element
function J1681W(e){ 
    //Element
    e.preventDefault()
    //Element
    let J7F02R = document.getElementById( "Web_F87699" ).value
    //Element
    const B130QM = async() => {
        //Element
        const YV685C = await Axios.get( "https://api.renga.com.tr/slider/delete", {
            //Element
            params : {
                //Element
                ID : J7F02R
            }
        })
        //Element
        .catch( J082IV => {
            //Element
            console.log( J082IV )
        })
    }
    //Element
    B130QM()
    //Element
    window.location.href = "/slider-order"
}
//Element
function New(){
    //Element
    let BH846E   = "https://api.renga.com.tr/images/"
    //Element
    const { ID } = useParams()
    //Element
    let RMZ096, BJ430L, Y3518J
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const OT2798 = async() => {
            //Element
            const TZ013J = await Axios.get( "https://api.renga.com.tr/slider/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            } )
            //Element
            C68006( TZ013J.data )
        }
        //Element
        OT2798()
    }, [] )
    //Element
    G7TI26.map( (J7F78O) => {
        //Element
        RMZ096 = J7F78O.Title
        //Element
        BJ430L = J7F78O.Image
        //Element
        Y3518J = J7F78O.Target
    })
    //Element
    useEffect( () => {
        //Element
        let Q3178X   = BH846E + BJ430L
        //Element
        const D271I6 = document.querySelector( "#Web_S696YN" )
        //Element
        D271I6.src   = Q3178X
        //Element
        document.getElementsByClassName( "klf458" )[0].classList.remove( "d-none" )
        //Element
        document.getElementsByClassName( "p43687" )[0].classList.add( "d-none" )
    })
    //Element
    sessionStorage.setItem( "Web_Y379P8", BJ430L )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Slider"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Bilgi Güncelleme"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "ID"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_F87699", name: "Web_F87699", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Adı"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_C157RB", name: "Web_C157RB", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: RMZ096, type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Ürün"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_BA3L06", name: "Web_BA3L06", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue : Y3518J, type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_IF6E80", name: "Web_IF6E80", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: J1681W },
                                    //Element
                                    "Sil"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: L9627W },
                                    //Element
                                    "Güncelle"
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default New;