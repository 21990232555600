//Install
import React, { useState, useEffect } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import { CKEditor } from "@ckeditor/ckeditor5-react";
//Install
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//Install
import Header from "../../Header";
//Install
import Footer from "../../Footer";

//Element
function A5L82C(){
    //Element
    document.title = "Merhaba : Renga - Renga.com.tr"
}
//Element
function J3U4S1(e){
    //Element
    e.preventDefault()
    //Element
    let TC8V96, F85PP0
    //Element
    TC8V96 = document.getElementById( "Web_K7B908" ).value
    //Element
    F85PP0 = document.getElementsByClassName( "ck-editor__editable_inline" )[0].innerHTML
    //Element
    const HX859Y = async() => {
        //Element
        const AHT028 = await Axios.get( "https://api.renga.com.tr/career/update", {
            //Element
            params : {
                //Element
                Title   : TC8V96,
                //Element
                Summary : F85PP0
            }
        })
        //Element
        .catch( A5496U => {
            //Element
            console.log( A5496U )
        })
    }
    //Element
    HX859Y()
    //Element
    window.location.href = "/human-resource"
}
//Element
function Default(){
    //Element
    A5L82C()
    //Element
    let E34245 = new Date()
    //Element
    let YS014L = [ "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık" ]
    //Element
    let E61248 = E34245.toLocaleDateString( "tr-TR" )
    //Element
    let E89J9A = E34245.getDate()
    //Element
    let L0047O = YS014L[ E34245.getMonth() ]
    //Element
    let H8E93O = E34245.getFullYear()
    //Element
    let R991JQ = E34245.getHours()
    //Element
    let A4621K = E34245.getMinutes()
    //Element
    let B348QM = ":"
    //Element
    let P10047 = " "
    //Element
    let D767O5 = E89J9A + P10047 + L0047O + P10047 + H8E93O + P10047 + R991JQ + B348QM + A4621K
    //Element
    let J458Y6, L691Z8, A880F0
    //Element
    const [ XN336I, F757J5 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const W2UA90 = async() => {
            //Element
            const XG1955 = await Axios.get( "https://api.renga.com.tr/career", {} )
            //Element
            F757J5( XG1955.data )
        }
        //Element
        W2UA90()
    }, [] )
    //Element
    XN336I.map( (M3323L) => {
        //Element
        J458Y6 = M3323L.ID
        //Element
        L691Z8 = M3323L.Title
        //Element
        A880F0 = M3323L.Summary
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v9339k" },
        //Element
        React.createElement( Header, { ID: "0", Title: "Merhaba", Status: D767O5 } ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ga8x38" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                                    //Element
                                    "İnsan Kaynakları"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                                    //Element
                                    "Bilgi Düzenleme"
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
                                //Element
                                React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: J3U4S1 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "ID"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_G1J75C", name: "Web_G1J75C", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: J458Y6, type: "text", required: "required", disabled: "disabled" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "Ad"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_K7B908", name: "Web_K7B908", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: L691Z8, type: "text", required: "required" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "Açıklama"
                                        ),
                                        //Element
                                        React.createElement( CKEditor, { editor: ClassicEditor, data: A880F0 } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "Tarih"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_I0D9J0", name: "Web_I0D9J0", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", placeholder: E61248, type: "text", required: "required", disabled: "disabled" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "button", { id: "Web_I754U4", name: "Web_I754U4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                                            //Element
                                            "Gönder"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( Footer, {} )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Default;