//Install
import React, { Component } from "react";
//Install
import { ReactComponent as M8042H } from "../../Media/Icon/014.svg";

//Element
class Menu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 k347lf" },
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/overview" },
                    //Element
                    "Genel"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/corporation" },
                    //Element
                    "Kurumsal"
                ),
                //Element
                React.createElement( "ul", { className: "float-start w-100 p-0 pt-2 pb-2 m-0 position-absolute yj724o" },
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/timeline-order" },
                            //Element
                            "Tarihçe",
                            //Element
                            React.createElement( M8042H, {} )
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 pt-2 pb-2 m-0 position-absolute" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/timeline" },
                                    //Element
                                    "Tarih Ekle",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/timeline-order" },
                                    //Element
                                    "Tümünü Göster",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/corporation" },
                            //Element
                            "Hakkımızda",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/factory-order" },
                            //Element
                            "Fabrikamız",
                            //Element
                            React.createElement( M8042H, {} )
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 pt-2 pb-2 m-0 position-absolute" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/factory" },
                                    //Element
                                    "Fotoğraf Ekle",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/factory-order" },
                                    //Element
                                    "Tümünü Göster",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/prize-order" },
                            //Element
                            "Ödüllerimiz",
                            //Element
                            React.createElement( M8042H, {} )
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 pt-2 pb-2 m-0 position-absolute" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/prize" },
                                    //Element
                                    "Ödül Ekle",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/prize-order" },
                                    //Element
                                    "Tümünü Göster",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/document-order" },
                            //Element
                            "Belgelerimiz",
                            //Element
                            React.createElement( M8042H, {} )
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 pt-2 pb-2 m-0 position-absolute" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/document" },
                                    //Element
                                    "Belge Ekle",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/document-order" },
                                    //Element
                                    "Tümünü Göster",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/setting" },
                            //Element
                            "Şirket Bilgileri",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/sustainability" },
                            //Element
                            "Sürdürülebilirlik",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/policy" },
                            //Element
                            "Misyon ve Vizyon",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/fair-order" },
                            //Element
                            "Katıldığımız Fuarlar",
                            //Element
                            React.createElement( M8042H, {} )
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 pt-2 pb-2 m-0 position-absolute" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/fair" },
                                    //Element
                                    "Fuar Ekle",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/fair-order" },
                                    //Element
                                    "Tümünü Göster",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/faq" },
                            //Element
                            "Sıkça Sorulan Sorular",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/reference-order" },
                            //Element
                            "Kimlere Hizmet Sunuldu?",
                            //Element
                            React.createElement( M8042H, {} )
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 pt-2 pb-2 m-0 position-absolute" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/reference" },
                                    //Element
                                    "Referans Ekle",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/reference-order" },
                                    //Element
                                    "Tümünü Göster",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/blog-order" },
                    //Element
                    "Blog"
                ),
                //Element
                React.createElement( "ul", { className: "float-start w-100 p-0 pt-2 pb-2 m-0 position-absolute yj724o" },
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/blog" },
                            //Element
                            "Blog Ekle",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/blog-order" },
                            //Element
                            "Tümünü Göster",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/video-order" },
                    //Element
                    "Video"
                ),
                //Element
                React.createElement( "ul", { className: "float-start w-100 p-0 pt-2 pb-2 m-0 position-absolute yj724o" },
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/video" },
                            //Element
                            "Video Ekle",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/video-order" },
                            //Element
                            "Tümünü Göster",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/slider-order" },
                    //Element
                    "Manşet"
                ),
                //Element
                React.createElement( "ul", { className: "float-start w-100 p-0 pt-2 pb-2 m-0 position-absolute yj724o" },
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/slider" },
                            //Element
                            "Yeni Ekle",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/slider-order" },
                            //Element
                            "Tümünü Göster",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/news" },
                    //Element
                    "Haber"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/product-order" },
                    //Element
                    "e-Ticaret"
                ),
                //Element
                React.createElement( "ul", { className: "float-start w-100 p-0 pt-2 pb-2 m-0 position-absolute yj724o" },
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/tritan" },
                            //Element
                            "Tritan",
                            //Element
                            React.createElement( M8042H, {} )
                        ),
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/product-order" },
                            //Element
                            "Ürünler",
                            //Element
                            React.createElement( M8042H, {} )
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 position-absolute" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/product" },
                                    //Element
                                    "Ürün Ekle",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/product-order" },
                                    //Element
                                    "Tümünü Göster",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            ),
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "#" },
                            //Element
                            "Kategoriler",
                            //Element
                            React.createElement( M8042H, {} )
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 pt-2 pb-2 m-0 position-absolute" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/color" },
                                    //Element
                                    "Renk",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/volume" },
                                    //Element
                                    "Hacim",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/variety" },
                                    //Element
                                    "Çeşitlilik",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/bottle" },
                                    //Element
                                    "Mataralar",
                                    //Element
                                    React.createElement( M8042H, {} )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/human-resource" },
                    //Element
                    "Kariyer"
                ),
                //Element
                React.createElement( "ul", { className: "float-start w-100 p-0 pt-2 pb-2 m-0 position-absolute yj724o" },
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/human-resource" },
                            //Element
                            "Kariyer Fırsatları",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/application" },
                            //Element
                            "Başvurular",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/status" },
                            //Element
                            "Başvuru Yapılacak Bölümler",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "#" },
                    //Element
                    "Yardım"
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;