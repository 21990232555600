//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function I1520U(e){ 
    //Element
    e.preventDefault()
    //Element
    let KQ159U, Z5IO49, C9A15L, C7035J, A7660O, B1D61Q, IR172D, W1YM63, K0H626, WQY165, H0762P, G1MZ84, T19N2O, Y4540S, G4X095, I3S507, NY9X60, W5248P
    //Element
    KQ159U = document.getElementById( "Web_WJ611K" ).value
    //Element
    Z5IO49 = document.getElementById( "Web_S1T1X4" ).value
    //Element
    C9A15L = document.getElementById( "Web_CV18D1" ).value
    //Element
    C7035J = document.getElementById( "Web_D8G820" ).value
    //Element
    A7660O = document.getElementById( "Web_L921LX" ).value
    //Element
    B1D61Q = document.getElementById( "Web_A9ZV20" ).value
    //Element
    IR172D = document.getElementById( "Web_E8H67X" ).value
    //Element
    W1YM63 = document.getElementById( "Web_D1751Z" ).value
    //Element
    K0H626 = document.getElementById( "Web_L7DD70" ).value
    //Element
    WQY165 = document.getElementById( "Web_K61375" ).value
    //Element
    H0762P = document.getElementById( "Web_D2N2X3" ).value
    //Element
    G1MZ84 = document.getElementById( "Web_G946P0" ).value
    //Element
    T19N2O = document.getElementById( "Web_QM56A5" ).value
    //Element
    Y4540S = document.getElementById( "Web_U64Q7N" ).value
    //Element
    G4X095 = document.getElementById( "Web_T577P1" ).value
    //Element
    I3S507 = document.getElementById( "Web_I16764" ).value
    //Element
    NY9X60 = sessionStorage.getItem( "Web_J7M72" )
    //Element
    W5248P = document.getElementById( "Web_R2U0Q8" ).value
    //Element
    const G3N00R = async() => {
        //Element
        const PN090F = await Axios.get( "https://api.renga.com.tr/application/update", {
            //Element
            params : {
                //Element
                ID         : KQ159U,
                //Element
                Name       : Z5IO49,
                //Element
                Surname    : C9A15L,
                //Element
                Date       : C7035J,
                //Element
                Email      : A7660O,
                //Element
                Telephone  : B1D61Q,
                //Element
                Address    : IR172D,
                //Element
                City       : W1YM63,
                //Element
                Day        : K0H626,
                //Element
                Month      : WQY165,
                //Element
                Year       : H0762P,
                //Element
                Gender     : G1MZ84,
                //Element
                School     : T19N2O,
                //Element
                Section    : Y4540S,
                //Element
                Graduation : G4X095,
                //Element
                Position   : I3S507,
                //Element
                CV         : NY9X60,
                //Element
                Letter     : W5248P
            }
        })
        //Element
        .catch( H1ME38 => {
            //Element
            console.log( H1ME38 )
        })
    }
    //Element
    G3N00R()
    //Element
    window.location.href = "/application"
}
//Element
function QK9370(e){ 
    //Element
    e.preventDefault()
    //Element
    let W28G93 = document.getElementById( "Web_WJ611K" ).value
    //Element
    const B3128S = async() => {
        //Element
        const Z4584I = await Axios.get( "https://api.renga.com.tr/application/delete", {
            //Element
            params : {
                //Element
                ID : W28G93
            }
        })
        //Element
        .catch( HT597S => {
            //Element
            console.log( HT597S )
        })
    }
    //Element
    B3128S()
    //Element
    window.location.href = "/application"
}
//Element
function Edit(){
    //Element
    const { ID } = useParams()
    //Element
    let T01T26, I815YZ, ON3829, M8Y61S, U339UE, N5G159, J389T9, C9LX36, E32416, X42658, J4F65B, W14932, D106GJ, EV913L, B792CY, L494UT, Q7445E
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const KX6M44 = async() => {
            //Element
            const W1771T = await Axios.get( "https://api.renga.com.tr/application/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            } )
            //Element
            C68006( W1771T.data )
        }
        //Element
        KX6M44()
    }, [] )
    //Element
    let E5M060   = "https://api.renga.com.tr/images/"
    //Element
    G7TI26.map( (F93W5F) => {
        //Element
        T01T26 = F93W5F.Name
        //Element
        I815YZ = F93W5F.Surname
        //Element
        ON3829 = F93W5F.Date
        //Element
        M8Y61S = F93W5F.Email
        //Element
        U339UE = F93W5F.Telephone
        //Element
        N5G159 = F93W5F.Address
        //Element
        J389T9 = F93W5F.City
        //Element
        C9LX36 = F93W5F.Day
        //Element
        E32416 = F93W5F.Month
        //Element
        J4F65B = F93W5F.Year
        //Element
        X42658 = F93W5F.Gender
        //Element
        W14932 = F93W5F.School
        //Element
        D106GJ = F93W5F.Section
        //Element
        EV913L = F93W5F.Graduation
        //Element
        B792CY = F93W5F.Position
        //Element
        document.getElementById( "Web_H976TK" ).value = E5M060 + F93W5F.CV
        //Element
        L494UT = F93W5F.CV
        //Element
        Q7445E = F93W5F.Letter
    })
    //Element
    let LN542A   = E5M060 + L494UT
    //Element
    sessionStorage.setItem( "Web_J7M72C", L494UT )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Başvurular"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Bilgi Güncelleme"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "ID"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_WJ611K", name: "Web_WJ611K", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Tarih"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_D8G820", name: "Web_D8G820", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ON3829, type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Şehir"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_D1751Z", name: "Web_D1751Z", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: J389T9, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Ad"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_S1T1X4", name: "Web_S1T1X4", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: T01T26, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Soyad"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_CV18D1", name: "Web_CV18D1", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: I815YZ, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Email"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_L921LX", name: "Web_L921LX", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: M8Y61S, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Telefon"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_A9ZV20", name: "Web_A9ZV20", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: U339UE, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:8 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Adres"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_E8H67X", name: "Web_E8H67X", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: N5G159, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Gün"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_L7DD70", name: "Web_L7DD70", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: C9LX36, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Ay"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_K61375", name: "Web_K61375", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: E32416, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Yıl"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_D2N2X3", name: "Web_D2N2X3", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: J4F65B, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Cinsiyet"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_G946P0", name: "Web_G946P0", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: X42658, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:8 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Başvurulan Pozisyon"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_I16764", name: "Web_I16764", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: B792CY, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Okul"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_QM56A5", name: "Web_QM56A5", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: W14932, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Bölüm"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_U64Q7N", name: "Web_U64Q7N", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: D106GJ, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Yıl"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_T577P1", name: "Web_T577P1", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: EV913L, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "CV"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_H976TK", name: "Web_H976TK", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: LN542A, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Ön Yazı"
                            ),
                            //Element
                            React.createElement( "textarea", { id: "Web_R2U0Q8", name: "Web_R2U0Q8", className: "float-start w-100 p-3 m-0 mt-2 k1s43b", value: Q7445E, rows: "5", cols: "20", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                            //Element
                            React.createElement( Row, {},
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                        //Element
                                        React.createElement( "button", { id: "Web_IF6E80", name: "Web_IF6E80", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: QK9370 },
                                            //Element
                                            "Sil"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                        //Element
                                        React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: I1520U },
                                            //Element
                                            "Güncelle"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;