//Install
import React, { useState, useEffect } from "react";
//Install
import Axios from "axios";

//Element
function Q76827(e){
    //Element
    e.preventDefault()
    //Element
    let U6WQ90, PDB246, R582L4
    //Element
    U6WQ90 = document.getElementById( "Web_H2HR56" ).value
    //Element
    PDB246 = document.getElementById( "Web_N3Q236" ).value
    //Element
    R582L4 = document.getElementById( "Web_JQ3863" ).value
    //Element
    const E00V35 = async() => {
        //Element
        const S697U8 = await Axios.get( "https://api.renga.com.tr/bottle/add", {
            //Element
            params : {
                //Element
                Title    : U6WQ90,
                //Element
                Category : PDB246,
                //Element
                Date     : R582L4
            }
        })
        //Element
        .catch( MD20Y0 => {
            //Element
            console.log( MD20Y0 )
        })
    }
    //Element
    E00V35()
    //Element
    window.location.href = "/bottle"
}
//Element
function New(){
    //Element
    let I1A25W = new Date()
    //Element
    let C2V07N = I1A25W.toLocaleDateString( "tr-TR" )
    //Element
    const [ D79551, IK946T ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const M10268 = async() => {
            //Element
            const NM9557 = await Axios.get( "https://api.renga.com.tr/variety", {} )
            //Element
            IK946T( NM9557.data )
        }
        //Element
        M10268()
    }, [] )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Matara"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Yeni Ekle"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: Q76827 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Matara ID"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_Q230GB", name: "Web_Q230GB", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: "0", type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Matara Adı"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_H2HR56", name: "Web_H2HR56", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Kategori"
                    ),
                    //Element
                    React.createElement( "select", { id: "Web_N3Q236", name: "Web_N3Q236", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                        //Element
                        D79551.map( (H3419W) => {
                            //Element
                            return React.createElement( "option", { key: H3419W.ID, value: H3419W.ID },
                                //Element
                                H3419W.Title
                            )
                        })
                    )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Tarih"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_JQ3863", name: "Web_JQ3863", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: C2V07N, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                    //Element
                    React.createElement( "button", { id: "Web_D4771Y", name: "Web_D4771Y", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                        //Element
                        "Gönder"
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default New;