//Install
import React from "react";
//Install
import Axios from "axios";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Upload from "../../Shortcode/Product/Image";

//Element
function Q312J1(e){
    //Element
    e.preventDefault()
    //Element
    let F5C27V, X08EK3, D83JX8, YE5S17, X7G0H0, CE026S, J4671Y, Q802NK, C4Y46F
    //Element
    let INW968, CMR289, G7Y3U9
    //Element
    F5C27V = document.getElementById( "Web_G64R9F" ).value
    //Element
    X08EK3 = document.getElementById( "Web_P9340F" ).value
    //Element
    D83JX8 = document.getElementById( "Web_BZ457J" ).value
    //Element
    YE5S17 = document.getElementById( "Web_P49O4A" ).value
    //Element
    X7G0H0 = sessionStorage.getItem( "Web_JD126T" )
    //Element
    CE026S = document.getElementById( "Web_N5X6E9" ).files.length
    //Element
    J4671Y = document.getElementsByClassName( "p43687" )[0]
    //Element
    Q802NK = document.getElementsByClassName( "uh0823" )[0]
    //Element
    C4Y46F = document.getElementById( "Web_H484N5" ).value
    //Element
    INW968 = sessionStorage.getItem( "Web_L3N3U8" )
    //Element
    CMR289 = sessionStorage.getItem( "Web_N7O7F6" )
    //Element
    G7Y3U9 = sessionStorage.getItem( "Web_Y748IW" )
    //Element
    if( D83JX8 == null || D83JX8 == "" ){
        //Element
        D83JX8 = ""
    } else if( YE5S17 == null || YE5S17 == "" ){
        //Element
        YE5S17 = ""
    }
    //Element
    if( CE026S == "0" ){
        //Element
        J4671Y.style.border = "1px solid #1B84FF"
        //Element
        Q802NK.innerHTML    = "Lütfen Fotoğraf Seçin."
        //Element
        J4671Y.focus()
    //Element
    } else {
        //Element
        J4671Y.style.border = "1px solid #FFFFFF"
        //Element
        const R2Z009 = async() => {
            //Element
            const E581TJ = await Axios.get( "https://api.renga.com.tr/prize/add", {
                //Element
                params : {
                    //Element
                    Title   : F5C27V,
                    //Element
                    One     : X08EK3,
                    //Element
                    Two     : D83JX8,
                    //Element
                    Three   : YE5S17,
                    //Element
                    Image   : X7G0H0,
                    //Element
                    Summary : C4Y46F,
                    //Element
                    First   : INW968,
                    //Element
                    Second  : CMR289,
                    //Element
                    Third   : G7Y3U9
                }
            })
            //Element
            .catch( JU6952 => {
                //Element
                console.log( JU6952 )
            })
        }
        //Element
        R2Z009()
        //Element
        window.location.href = "/prize-order"
    }
}
//Element
function New(){
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Ödül"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Yeni Ekle"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: Q312J1 },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "ID"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_W15250", name: "Web_W15250", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: "0", type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Ödül Başlığı"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_G64R9F", name: "Web_G64R9F", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                        //Element
                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                            //Element
                            "Açıklama"
                        ),
                        //Element
                        React.createElement( "textarea", { id: "Web_H484N5", name: "Web_H484N5", className: "float-start w-100 p-3 m-0 mt-2 a49170", required: "required", rows: "5", cols: "20" } )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Ödül"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_P9340F", name: "Web_P9340F", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Ödül"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_BZ457J", name: "Web_BZ457J", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Ödül"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_P49O4A", name: "Web_P49O4A", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:4 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_R494VD" },
                                        //Element
                                        "Fotoğraf"
                                    ),
                                    //Element
                                    React.createElement( Upload, { ID: "Web_R494VD", For: "Web_N87TJ3", SVG: "Web_V32KA9", Image: "Web_V5K852" } )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:4 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_Y123I3" },
                                        //Element
                                        "Fotoğraf"
                                    ),
                                    //Element
                                    React.createElement( Upload, { ID: "Web_Y123I3", For: "Web_Y8BN79", SVG: "Web_QR150K", Image: "Web_XG45T3" } )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:4 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_W5F6C8" },
                                        //Element
                                        "Fotoğraf"
                                    ),
                                    //Element
                                    React.createElement( Upload, { ID: "Web_W5F6C8", For: "Web_E2F4K5", SVG: "Web_Q0V66Y", Image: "Web_Y1V817" } )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                        //Element
                        React.createElement( "button", { id: "Web_Q24564", name: "Web_Q24564", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                            //Element
                            "Gönder"
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default New;