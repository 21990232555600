//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function GBB312(e){ 
    //Element
    e.preventDefault()
    //Element
    let Y926AK, G1380G, H2L4Q6
    //Element
    Y926AK = document.getElementById( "Web_WJ611K" ).value
    //Element
    G1380G = document.getElementById( "Web_F2K32H" ).value
    //Element
    H2L4Q6 = document.getElementById( "Web_QX7787" ).value
    //Element
    const EP029X = async() => {
        //Element
        const R38S5O = await Axios.get( "https://api.renga.com.tr/news/update", {
            //Element
            params : {
                //Element
                ID    : Y926AK,
                //Element
                Title : G1380G,
                //Element
                Date  : H2L4Q6
            }
        })
        //Element
        .catch( A84X4O => {
            //Element
            console.log( A84X4O )
        })
    }
    //Element
    EP029X()
    //Element
    window.location.href = "/news"
}
//Element
function P39J2F(e){ 
    //Element
    e.preventDefault()
    //Element
    let M6D09O
    //Element
    M6D09O = document.getElementById( "Web_WJ611K" ).value
    //Element
    const MO699L = async() => {
        //Element
        const H23793 = await Axios.get( "https://api.renga.com.tr/news/delete", {
            //Element
            params : {
                //Element
                ID : M6D09O
            }
        })
        //Element
        .catch( F1A502 => {
            //Element
            console.log( F1A502 )
        })
    }
    //Element
    MO699L()
    //Element
    window.location.href = "/news"
}
//Element
function Edit(){
    //Element
    let T1B50K = new Date()
    //Element
    let A494BZ = T1B50K.toLocaleDateString( "tr-TR" )
    //Element
    const { ID } = useParams()
    //Element
    let PW211B
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const XU136J = async() => {
            //Element
            const C905J7 = await Axios.get( "https://api.renga.com.tr/news/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            } )
            //Element
            C68006( C905J7.data )
        }
        //Element
        XU136J()
    }, [] )
    //Element
    G7TI26.map( (N31990) => {
        //Element
        PW211B = N31990.Title
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Haber"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Bilgi Güncelleme"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "ID"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_WJ611K", name: "Web_WJ611K", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Adı"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_F2K32H", name: "Web_F2K32H", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: PW211B, type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Tarih"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_QX7787", name: "Web_QX7787", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: A494BZ, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_IF6E80", name: "Web_IF6E80", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: P39J2F },
                                    //Element
                                    "Sil"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: GBB312 },
                                    //Element
                                    "Güncelle"
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;