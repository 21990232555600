//Install
import React, { Component } from "react";
//Install
import Axios from "axios";
//Install
import { ReactComponent as L98UP5 } from "../../../Media/Icon/012.svg";
//Install
import { ReactComponent as N06N42 } from "../../../Media/Icon/013.svg";

//Element
class Upload extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            LF916D : null,
            //Element
            P7419F : null
        }
    }
    //Element
    V49Z2C = (e) => {
        //Element
        const YI8168 = new FormData()
        //Element
        let Q92R45   = document.getElementsByClassName( "p43687" )[0]
        //Element
        let V0I347   = document.querySelector( ".f5f48j" )
        //Element
        let W1S30Q   = document.getElementsByClassName( "klf458" )[0]
        //Element
        let VDX658   = document.getElementById( "Web_L277V9" )
        //Element
        let K92132   = document.getElementsByClassName( "uh0823" )[0]
        //Element
        let J015SS   = document.getElementById( "Web_Q2KW04" )
        //Element
        let JUL570   = document.getElementById( "Web_N7G0G4" )
        //Element
        if( J015SS != null ){
            //Element
            J015SS.disabled = true
        }
        //Element
        if( JUL570 != null ){
            //Element
            JUL570.disabled = true
        }
        //Element
        Q92R45.classList.add( "d-none" )
        //Element
        W1S30Q.classList.remove( "d-none" )
        //Element
        if( e.target.files[0] != null ){
            //Element
            this.setState({
                //Element
                LF916D : e.target.files[0],
                //Element
                P7419F     : e.target.files[0].name
            })
            //Element
            VDX658.src = URL.createObjectURL( e.target.files[0] )
            //Element
            YI8168.append( "image", e.target.files[0] )
            //Element
            Axios.post( "https://api.renga.com.tr/upload", YI8168 )
            //Element
            .then( N856ER => {
                //Element
                sessionStorage.removeItem( "Web_Y68590" )
                //Element
                sessionStorage.setItem( "Web_Y68590", N856ER.data )
                //Element
                if( N856ER.data != null ){
                    //Element
                    if( J015SS != null ){
                        //Element
                        J015SS.disabled  = false
                    }
                    //Element
                    if( JUL570 != null ){
                        //Element
                        JUL570.disabled  = false
                    }
                }
            })
            //Element
            K92132.innerHTML = "Fotoğraf"
        }
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 mb-3 c971b1" },
                //Element
                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                    //Element
                    "Fotoğraf"
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                    //Element
                    "Sadece *.png, *.jpg ve *.jpeg Formatları Kabul Edilir."
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 ix0q18" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 d8z4l3" },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 kl579w" },
                        //Element
                        React.createElement( "div", { className: "float-start w-auto p-0 m-0 p43687" },
                            //Element
                            React.createElement( L98UP5, {} )
                        ),
                        //Element
                        React.createElement( "div", { className: "float-start w-auto p-0 m-0 d-none klf458" },
                            //Element
                            React.createElement( "img", { id: "Web_L277V9", alt: "Fotoğraf", title: "Fotoğraf", src: "", width: "200", height: "auto" } )
                        ),
                        //Element
                        React.createElement( "label", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute rounded-circle f5f48j", htmlFor: "Web_N5X6E9" },
                            //Element
                            React.createElement( N06N42, {} ),
                            //Element
                            React.createElement( "input", { id: "Web_N5X6E9", name: "Web_N5X6E9", className: "d-none", type: "file", accept: ".png, .jpg, .jpeg", onChange: this.V49Z2C } )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Upload;