//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import { CKEditor } from "@ckeditor/ckeditor5-react";
//Install
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//Install
import Upload from "../../Shortcode/Product/Image";

//Element
function E641ZE(e){ 
    //Element
    e.preventDefault()
}
//Element
function IZ6H91(e){
    //Element
    e.preventDefault()
    //Element
    let SH8707, M2PT56, M01885, L539X9, IJI641, V81726
    //Element
    SH8707 = document.getElementById( "Web_WY1M47" ).value
    //Element
    M2PT56 = document.getElementsByClassName( "ck-editor__editable_inline" )[0].innerHTML
    //Element
    M01885 = sessionStorage.getItem( "Web_JD126T" )
    //Element
    L539X9 = sessionStorage.getItem( "Web_L3N3U8" )
    //Element
    IJI641 = sessionStorage.getItem( "Web_N7O7F6" )
    //Element
    V81726 = sessionStorage.getItem( "Web_Y748IW" )
    //Element
    const U2685C = async() => {
        //Element
        const ON533I = await Axios.get( "https://api.renga.com.tr/tritan/update", {
            //Element
            params : {
                //Element
                Title   : SH8707,
                //Element
                Summary : M2PT56,
                //Element
                Image   : M01885,
                //Element
                First   : L539X9,
                //Element
                Second  : IJI641,
                //Element
                Third   : V81726
            }
        })
        //Element
        .catch( F5RH50 => {
            //Element
            console.log( F5RH50 )
        })
    }
    //Element
    U2685C()
    //Element
    window.location.href = "/tritan"
}
//Element
function Edit(){
    //Element
    let R0A49E = "https://api.renga.com.tr/images/"
    //Element
    let Y81986 = ""
    //Element
    let O892H9 = ""
    //Element
    const PM942R = document.getElementById( "Web_R494VD" )
    //Element
    const XO6D66 = document.getElementById( "Web_Y123I3" )
    //Element
    const W1SX87 = document.getElementById( "Web_W5F6C8" )
    //Element
    let D1M1Y3, BN511E, Q1ZB19, BM7837, C3Q133, I858WO, A437UI
    //Element
    const [ XN336I, F757J5 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const SM3438 = async() => {
            //Element
            const E0YX84 = await Axios.get( "https://api.renga.com.tr/tritan", {} )
            //Element
            F757J5( E0YX84.data )
        }
        //Element
        SM3438()
    }, [] )
    //Element
    XN336I.map( (XT9106) => {
        //Element
        D1M1Y3 = XT9106.ID
        //Element
        BN511E = XT9106.Title
        //Element
        Q1ZB19 = XT9106.Summary
        //Element
        BM7837 = XT9106.Image
        //Element
        C3Q133 = XT9106.First
        //Element
        I858WO = XT9106.Second
        //Element
        A437UI = XT9106.Third
        //Element
        sessionStorage.setItem( "Web_JD126T", BM7837 )
        //Element
        sessionStorage.setItem( "Web_L3N3U8", XT9106.First )
        //Element
        sessionStorage.setItem( "Web_N7O7F6", XT9106.Second )
        //Element
        sessionStorage.setItem( "Web_Y748IW", XT9106.Third )
    })
    //Element
    useEffect( () => {
        //Element
        let U3247E   = R0A49E + BM7837
        //Element
        const K1K84V = document.querySelector( "#Web_G13R7P" )
        //Element
        K1K84V.src   = U3247E
        //Element
        document.getElementsByClassName( "klf458" )[0].classList.remove( "d-none" )
        //Element
        document.getElementsByClassName( "p43687" )[0].classList.add( "d-none" )
    })
    //Element
    if( C3Q133 != null && C3Q133 != "null" && C3Q133 != "" ){
        //Element
        document.getElementById( "Web_V32KA9" ).classList.add( "d-none" )
        //Element
        document.getElementById( "Web_V5K852" ).classList.remove( "d-none" )
        //Element
        Y81986     = C3Q133
        //Element
        O892H9     = R0A49E + Y81986
        //Element
        PM942R.src = O892H9
        //Element
        if( sessionStorage.getItem( "Web_L3N3U8" ) ){
            //Element
            sessionStorage.removeItem( "Web_L3N3U8" )
            //Element
            sessionStorage.setItem( "Web_L3N3U8", C3Q133 )
        //Element
        } else {
            //Element
            sessionStorage.setItem( "Web_L3N3U8", C3Q133 )
        }
    }
    //Element
    if( I858WO != null && I858WO != "null" && I858WO != "" ){
        //Element
        document.getElementById( "Web_QR150K" ).classList.add( "d-none" )
        //Element
        document.getElementById( "Web_XG45T3" ).classList.remove( "d-none" )
        //Element
        Y81986      = I858WO
        //Element
        O892H9      = R0A49E + Y81986
        //Element
        XO6D66.src  = R0A49E + Y81986
        //Element
        if( sessionStorage.getItem( "Web_N7O7F6" ) ){
            //Element
            sessionStorage.removeItem( "Web_N7O7F6" )
            //Element
            sessionStorage.setItem( "Web_N7O7F6", I858WO )
        //Element
        } else {
            //Element
            sessionStorage.setItem( "Web_N7O7F6", I858WO )
        }
    }
    //Element
    if( A437UI != null && A437UI != "null" && A437UI != "" ){
        //Element
        document.getElementById( "Web_Q0V66Y" ).classList.add( "d-none" )
        //Element
        document.getElementById( "Web_Y1V817" ).classList.remove( "d-none" )
        //Element
        Y81986      = A437UI
        //Element
        O892H9      = R0A49E + Y81986
        //Element
        W1SX87.src  = R0A49E + Y81986
        //Element
        if( sessionStorage.getItem( "Web_Y748IW" ) ){
            //Element
            sessionStorage.removeItem( "Web_Y748IW" )
            //Element
            sessionStorage.setItem( "Web_Y748IW", A437UI )
        //Element
        } else {
            //Element
            sessionStorage.setItem( "Web_Y748IW", A437UI )
        }
    }
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Tritan"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Bilgi Güncelleme"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "ID"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_HZ04O4", name: "Web_HZ04O4", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: D1M1Y3, type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Ad"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_WY1M47", name: "Web_WY1M47", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: BN511E, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                        //Element
                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                            //Element
                            "Açıklama"
                        ),
                        //Element
                        React.createElement( CKEditor, { editor: ClassicEditor, data: Q1ZB19 } )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:4 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_R494VD" },
                                        //Element
                                        "Fotoğraf"
                                    ),
                                    //Element
                                    React.createElement( Upload, { ID: "Web_R494VD", For: "Web_N87TJ3", SVG: "Web_V32KA9", Image: "Web_V5K852" } )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:4 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_Y123I3" },
                                        //Element
                                        "Fotoğraf"
                                    ),
                                    //Element
                                    React.createElement( Upload, { ID: "Web_Y123I3", For: "Web_Y8BN79", SVG: "Web_QR150K", Image: "Web_XG45T3" } )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:4 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_W5F6C8" },
                                        //Element
                                        "Fotoğraf"
                                    ),
                                    //Element
                                    React.createElement( Upload, { ID: "Web_W5F6C8", For: "Web_E2F4K5", SVG: "Web_Q0V66Y", Image: "Web_Y1V817" } )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                    //Element
                                    React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: IZ6H91 },
                                        //Element
                                        "Güncelle"
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;