//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function M604D2(e){ 
    //Element
    e.preventDefault()
    //Element
    let L7H98O, X3048E, NKM082
    //Element
    L7H98O = document.getElementById( "Web_B21K31" ).value
    //Element
    X3048E = document.getElementById( "Web_YB170N" ).value
    //Element
    NKM082 = sessionStorage.getItem( "Web_MK9059" )
    //Element
    const L8A876 = async() => {
        //Element
        const G22RJ5 = await Axios.get( "https://api.renga.com.tr/fair/update", {
            //Element
            params : {
                //Element
                ID      : L7H98O,
                //Element
                Title   : X3048E,
                //Element
                Image   : NKM082
            }
        })
        //Element
        .catch( IP0597 => {
            //Element
            console.log( IP0597 )
        })
    }
    //Element
    L8A876()
    //Element
    window.location.href = "/fair-order"
}
//Element
function M221EN(e){ 
    //Element
    e.preventDefault()
    //Element
    let TO2384 = document.getElementById( "Web_B21K31" ).value
    //Element
    const SN7515 = async() => {
        //Element
        const ZE95G0 = await Axios.get( "https://api.renga.com.tr/fair/delete", {
            //Element
            params : {
                //Element
                ID : TO2384
            }
        })
        //Element
        .catch( X2I89D => {
            //Element
            console.log( X2I89D )
        })
    }
    //Element
    SN7515()
    //Element
    window.location.href = "/fair-order"
}
//Element
function Edit(){
    //Element
    let G117H0   = "https://api.renga.com.tr/images/"
    //Element
    const { ID } = useParams()
    //Element
    let L96AI2, V34O27, G692S7
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const KM78J8 = async() => {
            //Element
            const JM3P01 = await Axios.get( "https://api.renga.com.tr/fair/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            } )
            //Element
            C68006( JM3P01.data )
        }
        //Element
        KM78J8()
    }, [] )
    //Element
    G7TI26.map( (F1I2T8) => {
        //Element
        L96AI2 = F1I2T8.Title
        //Element
        V34O27 = F1I2T8.Summary
        //Element
        G692S7 = F1I2T8.Image
    })
    //Element
    useEffect( () => {
        //Element
        let P50SE8   = G117H0 + G692S7
        //Element
        const TB3786 = document.querySelector( "#Web_P7U70J" )
        //Element
        TB3786.src   = P50SE8
        //Element
        document.getElementsByClassName( "klf458" )[0].classList.remove( "d-none" )
        //Element
        document.getElementsByClassName( "p43687" )[0].classList.add( "d-none" )
    })
    //Element
    sessionStorage.setItem( "Web_MK9059", G692S7 )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Fuar"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Bilgi Düzenleme"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Fuar ID"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_B21K31", name: "Web_B21K31", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Fuar Başlığı"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_YB170N", name: "Web_YB170N", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: L96AI2, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Bilgilendirme"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_J056X7", name: "Web_J056X7", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                    //Element
                                    React.createElement( "button", { id: "Web_IF6E80", name: "Web_IF6E80", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: M221EN },
                                        //Element
                                        "Sil"
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                    //Element
                                    React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: M604D2 },
                                        //Element
                                        "Güncelle"
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;