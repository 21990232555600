//Install
import React, { useState, useEffect } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as U3S72F } from "../../../Media/Icon/011.svg";
//Install
import Axios from "axios";

//Element
function List(){
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const I0081N = async() => {
            //Element
            const GF0512 = await Axios.get( "https://api.renga.com.tr/document", {} )
            //Element
            C68006( GF0512.data )
        }
        //Element
        I0081N()
    }, [] )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Belge"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Tam Liste"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 sh874h" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 n2b78e" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d9287e" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 po732b" },
                                //Element
                                "Belge ID"
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d9287e" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 po732b" },
                                //Element
                                "Belge Başlığı"
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d9287e" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 po732b" },
                                //Element
                                "Bilgilendirme"
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d9287e" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 po732b" },
                                //Element
                                ""
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "ol", { className: "float-start w-100 p-0 m-0 p4s8r8" },
                //Element
                G7TI26.map( (S4C14H) => {
                    //Element
                    let GI325W = "/document-edit/"
                    //Element
                    let F5108E = GI325W + S4C14H.ID
                    //Element
                    return React.createElement( "li", { key: S4C14H.ID, className: "float-start w-100 p-0 m-0" },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 jl002z" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-auto p-0 m-0 text-truncate text-center" },
                                        //Element
                                        S4C14H.ID
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 jl002z" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-truncate" },
                                        //Element
                                        S4C14H.Title
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 jl002z" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-truncate" },
                                        //Element
                                        S4C14H.Summary
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-3 jl002z" },
                                    //Element
                                    React.createElement( "a", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 text-truncate", href: F5108E },
                                        //Element
                                        React.createElement( U3S72F, {} )
                                    )
                                )
                            )
                        )
                    )
                })
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default List;