//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function O82X6E(e){ 
    //Element
    e.preventDefault()
    //Element
    let H6T473, T8A629, F5B8G8, IU7024
    //Element
    H6T473 = document.getElementById( "Web_IE59A1" ).value
    //Element
    T8A629 = document.getElementById( "Web_U839UU" ).value
    //Element
    F5B8G8 = ""
    //Element
    IU7024 = sessionStorage.getItem( "Web_Y68590" )
    //Element
    const R7176F = async() => {
        //Element
        const GS4N19 = await Axios.get( "https://api.renga.com.tr/factory/update", {
            //Element
            params : {
                //Element
                ID      : H6T473,
                //Element
                Title   : T8A629,
                //Element
                Summary : F5B8G8,
                //Element
                Image   : IU7024
            }
        })
        //Element
        .catch( N484V1 => {
            //Element
            console.log( N484V1 )
        })
    }
    //Element
    R7176F()
    //Element
    window.location.href = "/factory-order"
}
//Element
function KF633D(e){ 
    //Element
    e.preventDefault()
    //Element
    let NX519B = document.getElementById( "Web_IE59A1" ).value
    //Element
    const U9722X = async() => {
        //Element
        const B70130 = await Axios.get( "https://api.renga.com.tr/factory/delete", {
            //Element
            params : {
                //Element
                ID : NX519B
            }
        })
        //Element
        .catch( GM062T => {
            //Element
            console.log( GM062T )
        })
    }
    //Element
    U9722X()
    //Element
    window.location.href = "/factory-order"
}
//Element
function Edit(){
    //Element
    let Y35548   = "https://api.renga.com.tr/images/"
    //Element
    const { ID } = useParams()
    //Element
    let D9360Q, UY3892
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const Y3F852 = async() => {
            //Element
            const J186E1 = await Axios.get( "https://api.renga.com.tr/factory/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            } )
            //Element
            C68006( J186E1.data )
        }
        //Element
        Y3F852()
    }, [] )
    //Element
    G7TI26.map( (A42F08) => {
        //Element
        D9360Q = A42F08.Title
        //Element
        UY3892 = A42F08.Image
    })
    //Element
    useEffect( () => {
        //Element
        let ALW079   = Y35548 + UY3892
        //Element
        const SV3I66 = document.querySelector( "#Web_L277V9" )
        //Element
        SV3I66.src   = ALW079
        //Element
        document.getElementsByClassName( "klf458" )[0].classList.remove( "d-none" )
        //Element
        document.getElementsByClassName( "p43687" )[0].classList.add( "d-none" )
    })
    //Element
    sessionStorage.setItem( "Web_Y68590", UY3892 )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Fabrikamız"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Bilgi Güncelleme"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Fotoğraf ID"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_IE59A1", name: "Web_IE59A1", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Fotoğraf Başlığı"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_U839UU", name: "Web_U839UU", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: D9360Q, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Bilgilendirme"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_RX48H4", name: "Web_RX48H4", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                    //Element
                                    React.createElement( "button", { id: "Web_IF6E80", name: "Web_IF6E80", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: KF633D },
                                        //Element
                                        "Sil"
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                    //Element
                                    React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: O82X6E },
                                        //Element
                                        "Güncelle"
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;