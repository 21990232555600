//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function FH810W(e){ 
    //Element
    e.preventDefault()
    //Element
    let S7O11G, Y4G058, G27KE4
    //Element
    S7O11G = document.getElementById( "Web_Z98J05" ).value
    //Element
    Y4G058 = document.getElementById( "Web_AK392G" ).value
    //Element
    G27KE4 = document.getElementById( "Web_H2K87F" ).value
    //Element
    const C647JS = async() => {
        //Element
        const X96825 = await Axios.get( "https://api.renga.com.tr/faq/update", {
            //Element
            params : {
                //Element
                ID       : S7O11G,
                //Element
                Question : Y4G058,
                //Element
                Reply    : G27KE4
            }
        })
        //Element
        .catch( Y01F9F => {
            //Element
            console.log( Y01F9F )
        })
    }
    //Element
    C647JS()
    //Element
    window.location.href = "/faq"
}
//Element
function B7E49V(e){ 
    //Element
    e.preventDefault()
    //Element
    let W7D24G = document.getElementById( "Web_Z98J05" ).value
    //Element
    const LF8X33 = async() => {
        //Element
        const P6Q888 = await Axios.get( "https://api.renga.com.tr/faq/delete", {
            //Element
            params : {
                //Element
                ID : W7D24G
            }
        })
        //Element
        .catch( B0E4C8 => {
            //Element
            console.log( B0E4C8 )
        })
    }
    //Element
    LF8X33()
    //Element
    window.location.href = "/faq"
}
//Element
function Edit(){
    //Element
    const { ID } = useParams()
    //Element
    let U6Y85C, J249AI
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const N7732E = async() => {
            //Element
            const U9534K = await Axios.get( "https://api.renga.com.tr/faq/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            } )
            //Element
            C68006( U9534K.data )
        }
        //Element
        N7732E()
    }, [] )
    //Element
    G7TI26.map( (ZUH445) => {
        //Element
        U6Y85C = ZUH445.Question
        //Element
        J249AI = ZUH445.Reply
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Sıkça Sorulan Sorular"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Bilgi Güncelleme"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "ID"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_Z98J05", name: "Web_Z98J05", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Soru"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_AK392G", name: "Web_AK392G", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: U6Y85C, type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Cevap"
                    ),
                    //Element
                    React.createElement( "textarea", { id: "Web_H2K87F", name: "Web_H2K87F", className: "float-start w-100 p-0 ps-3 pe-3 pt-3 pb-3 m-0 mt-2 k1s43b", defaultValue: J249AI, rows: "5", cols: "20", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_U70C69", name: "Web_U70C69", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: B7E49V },
                                    //Element
                                    "Sil"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_B87743", name: "Web_B87743", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: FH810W },
                                    //Element
                                    "Güncelle"
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;