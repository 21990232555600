//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function C23344(e){ 
    //Element
    e.preventDefault()
    //Element
    let Q17U46, D1NJ83, IGS346
    //Element
    Q17U46 = document.getElementById( "Web_AR220E" ).value
    //Element
    D1NJ83 = document.getElementById( "Web_ZB2344" ).value
    //Element
    IGS346 = sessionStorage.getItem( "Web_W0ZQ49" )
    //Element
    const M851IL = async() => {
        //Element
        const ND8568 = await Axios.get( "https://api.renga.com.tr/reference/update", {
            //Element
            params : {
                //Element
                ID      : Q17U46,
                //Element
                Title   : D1NJ83,
                //Element
                Image   : IGS346
            }
        })
        //Element
        .catch( T05P6H => {
            //Element
            console.log( T05P6H )
        })
    }
    //Element
    M851IL()
    //Element
    window.location.href = "/reference-order"
}
//Element
function MEA890(e){ 
    //Element
    e.preventDefault()
    //Element
    let L2769H = document.getElementById( "Web_AR220E" ).value
    //Element
    const KEI593 = async() => {
        //Element
        const X766QL = await Axios.get( "https://api.renga.com.tr/reference/delete", {
            //Element
            params : {
                //Element
                ID : L2769H
            }
        })
        //Element
        .catch( C170GE => {
            //Element
            console.log( C170GE )
        })
    }
    //Element
    KEI593()
    //Element
    window.location.href = "/reference-order"
}
//Element
function Edit(){
    //Element
    let D1C881   = "https://api.renga.com.tr/images/"
    //Element
    const { ID } = useParams()
    //Element
    let IO453V, Q590W8
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const W8834Q = async() => {
            //Element
            const S5W45A = await Axios.get( "https://api.renga.com.tr/reference/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            } )
            //Element
            C68006( S5W45A.data )
        }
        //Element
        W8834Q()
    }, [] )
    //Element
    G7TI26.map( (S9G61S) => {
        //Element
        IO453V = S9G61S.Title
        //Element
        Q590W8 = S9G61S.Image
    })
    //Element
    useEffect( () => {
        //Element
        let Q4W936   = D1C881 + Q590W8
        //Element
        const NS2255 = document.querySelector( "#Web_EKR230" )
        //Element
        NS2255.src   = Q4W936
        //Element
        document.getElementsByClassName( "klf458" )[0].classList.remove( "d-none" )
        //Element
        document.getElementsByClassName( "p43687" )[0].classList.add( "d-none" )
    })
    //Element
    sessionStorage.setItem( "Web_W0ZQ49", Q590W8 )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Referans"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Renga.com.tr"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Referans ID"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_AR220E", name: "Web_AR220E", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Firma Adı"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_ZB2344", name: "Web_ZB2344", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: IO453V, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Bilgilendirme"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_BO896B", name: "Web_BO896B", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                    //Element
                                    React.createElement( "button", { id: "Web_IF6E80", name: "Web_IF6E80", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: MEA890 },
                                        //Element
                                        "Sil"
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                    //Element
                                    React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: C23344 },
                                        //Element
                                        "Güncelle"
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;