//Install
import React, { useState, useEffect } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as A168TV } from "../../../Media/Icon/011.svg";
//Install
import Axios from "axios";

//Element
function List(){
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const M0556O = async() => {
            //Element
            const N573MN = await Axios.get( "https://api.renga.com.tr/blog", {} )
            //Element
            C68006( N573MN.data )
        }
        //Element
        M0556O()
    }, [] )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Blog"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Tam Liste"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 sh874h" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 n2b78e" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d9287e" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 po732b" },
                                //Element
                                "Blog ID"
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d9287e" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 po732b" },
                                //Element
                                "Blog Başlığı"
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d9287e" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 po732b" },
                                //Element
                                "Tarih"
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d9287e" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 po732b" },
                                //Element
                                ""
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "ol", { className: "float-start w-100 p-0 m-0 p4s8r8" },
                //Element
                G7TI26.map( (N9N53B) => {
                    //Element
                    let P5384H = "/blog-edit/"
                    //Element
                    let WI697G = P5384H + N9N53B.ID
                    //Element
                    return React.createElement( "li", { key: N9N53B.ID, className: "float-start w-100 p-0 m-0" },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 jl002z" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-auto p-0 m-0 text-truncate text-center" },
                                        //Element
                                        N9N53B.ID
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 jl002z" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-truncate" },
                                        //Element
                                        N9N53B.Title
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 jl002z" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-truncate" },
                                        //Element
                                        N9N53B.Date
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-3 jl002z" },
                                    //Element
                                    React.createElement( "a", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 text-truncate", href: WI697G },
                                        //Element
                                        React.createElement( A168TV, {} )
                                    )
                                )
                            )
                        )
                    )
                })
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default List;