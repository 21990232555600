//Install
import React from "react";
//Install
import Axios from "axios";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { CKEditor } from "@ckeditor/ckeditor5-react";
//Install
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//Element
function I7347I(e){
    //Element
    e.preventDefault()
    //Element
    let RSR589, QJ978R, Z4Q59K, SU2369, J5G2Y9, V949RP, M0MR05, CE026S, J4671Y, Q802NK
    //Element
    RSR589 = document.getElementById( "Web_W26U32" ).value
    //Element
    QJ978R = document.getElementById( "Web_V585WQ" ).value
    //Element
    Z4Q59K = document.getElementsByClassName( "ck-editor__editable_inline" )[0].innerHTML
    //Element
    SU2369 = sessionStorage.getItem( "Web_K6KU98" )
    //Element
    J5G2Y9 = document.getElementById( "Web_P0962M" ).value
    //Element
    V949RP = document.getElementById( "Web_N66390" ).value
    //Element
    M0MR05 = document.getElementById( "Web_M8EL55" ).value
    //Element
    CE026S = document.getElementById( "Web_N5X6E9" ).files.length
    //Element
    J4671Y = document.getElementsByClassName( "p43687" )[0]
    //Element
    Q802NK = document.getElementsByClassName( "uh0823" )[0]
    //Element
    if( CE026S == "0" ){
        //Element
        J4671Y.style.border = "1px solid #1B84FF"
        //Element
        Q802NK.innerHTML    = "Lütfen Fotoğraf Seçin."
        //Element
        J4671Y.focus()
    //Element
    } else {
        //Element
        J4671Y.style.border = "1px solid #FFFFFF"
        //Element
        const DZ431B = async() => {
            //Element
            const P6109H = await Axios.get( "https://api.renga.com.tr/blog/add", {
                //Element
                params : {
                    //Element QJ978R
                    Title    : RSR589,
                    //Element
                    Summary  : QJ978R,
                    //Element
                    Content  : Z4Q59K,
                    //Element
                    Image    : SU2369,
                    //Element
                    Date     : J5G2Y9,
                    //Element
                    Category : V949RP,
                    //Element
                    Author   : M0MR05
                    
                }
            })
            //Element
            .catch( E62660 => {
                //Element
                console.log( E62660 )
            })
        }
        //Element
        DZ431B()
        //Element
        window.location.href = "/blog-order"
    }
}
//Element
function New(){
    //Element
    let C205ID = new Date()
    //Element
    let UK915Y = C205ID.toLocaleDateString( "tr-TR" )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Blog"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Yeni Ekle"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: I7347I },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "ID"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_R6X85C", name: "Web_R6X85C", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: "0", type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Tarih"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_P0962M", name: "Web_P0962M", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", defaultValue: UK915Y, required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Yazar"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_M8EL55", name: "Web_M8EL55", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", defaultValue: "Renga.com.tr", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Blog Başlığı"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_W26U32", name: "Web_W26U32", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Özet Bilgi"
                            ),
                            //Element
                            React.createElement( "textarea", { id: "Web_V585WQ", name: "Web_V585WQ", className: "float-start w-100 p-0 ps-3 pe-3 pt-3 pb-3 m-0 mt-2 a49170", rows: "5", cols: "20", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Açıklama"
                            ),
                            //Element
                            React.createElement( CKEditor, { editor: ClassicEditor } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Kategori"
                            ),
                            //Element
                            React.createElement( "select", { id: "Web_N66390", name: "Web_N66390", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                //Element
                                React.createElement( "option", { value: "Blog" },
                                    //Element
                                    "Blog"
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                        //Element
                        React.createElement( "button", { id: "Web_M94E1F", name: "Web_M94E1F", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                            //Element
                            "Gönder"
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default New;